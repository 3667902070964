<template>
  <custom-modal :name="name" @before-close="dialog.cancel()">
    <confirm
      :title="dialog.state.title"
      :message="dialog.state.message"
      :is-html="dialog.state.isHtml"
      :cancel-text="dialog.state.cancelText"
      :ok-text="dialog.state.okText"
      @confirm="dialog.ok()"
      @cancel="dialog.cancel()"
    />
  </custom-modal>
</template>
<script>
import { Components } from "@zteam/booking-axe-sb";
import dialog from "@/plugins/dialog";

export default {
  name: "GlobalDialog",
  components: {
    confirm: Components.Confirm,
  },
  data() {
    return {
      dialog,
      name: "global-dialog",
    };
  },
  computed: {
    isActive() {
      return this.dialog.state.active;
    },
  },
  watch: {
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    },
  },
};
</script>

<style lang="scss">
.confirm__content__message {
  white-space: break-spaces;
}
</style>
