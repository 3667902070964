var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-layout"},[_c('Header',{staticClass:"header",on:{"burger-click":_vm.toggleSidebar}}),_c('div',{staticClass:"sidebar-with-content"},[_c('Sidebar',{attrs:{"active":_vm.isSidebarActive},on:{"update:active":function($event){_vm.isSidebarActive=$event}}}),_c('div',{staticClass:"space-container"},[_c('div',{staticClass:"content",class:{
          'm-iphone': _vm.$device.iphone,
          'm-iphoneX':
            _vm.$device.iphoneX || _vm.$device.iPhoneXR || _vm.$device.iPhoneXSMax,
          'm-ipad': _vm.$device.ipad,
          'm-safari': _vm.isSafari && _vm.$browserDetect.meta.version === '15',
        }},[_vm._t("default")],2)])],1),_c('Alert',{attrs:{"isShow":_vm.isShowStripeAlert,"message":"In order to receive payments for bookings please verify your Stripe account","variant":"secondary"},on:{"close":_vm.closeStripeAlert}},[_c('Button',{attrs:{"is-block":['xs', 'sm'].includes(_vm.$mq),"isLoading":_vm.isLoading,"isSmall":""},on:{"click":_vm.redirectToStripeOnboarding}},[_vm._v(" Verify ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }