import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";

export default {
  async fetchTerms({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const terms = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/terms`
      );
      commit("SET_LIST", terms);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchTermsById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const terms = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/terms`,
        id
      );
      commit("SET_EDITED_ITEM", terms);
    }
  },
  async updateTerms({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/terms`,
        id,
        data
      );
    }
  },
  async createTerms({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/terms`,
        data
      );
    }
  },
  async deleteTerms({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/terms`,
        id
      );
    }
  },
};
