import actions from "./actions";
import mutations from "./mutations";
import { SignaturesSortEnum, SignaturesSearchFieldEnum } from "@/helpers/enums";

const state = {
  list: [],
  editedItem: null,
  lastDoc: null,
  activeSort: SignaturesSortEnum.NEW,
  searchQuery: "",
  searchField: SignaturesSearchFieldEnum.FIRST_NAME,
  bookingReference: null,
  periodFilter: [],
  waiverIdFilter: null,
  signatureType: "",
  isSortEnabled: true,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
