import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  getPaginatedCollection,
  updateDocument,
} from "@/helpers/firebase-helpers";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";
import { where } from "firebase/firestore";
import { getBookingPrice } from "@/helpers/utils";

export default {
  async fetchAffiliates({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const affiliates = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/affiliates`
      );
      commit("SET_LIST", affiliates);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchAffiliateById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const affiliate = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/affiliates`,
        id
      );
      commit("SET_EDITED_ITEM", affiliate);
    }
  },
  async fetchAffiliateBookings(
    { commit, rootState, state },
    { limit, affiliateId, isInitial }
  ) {
    if (rootState.venues.selectedVenue?.id && affiliateId) {
      if (isInitial) {
        commit("SET_BOOKINGS", []);
      }

      const { data: bookings, lastDoc } = await getPaginatedCollection(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        state.lastDoc,
        limit,
        where("affiliateId", "==", affiliateId)
      );

      if (!lastDoc) return 0;

      commit("SET_BOOKINGS_LAST_DOC", lastDoc);

      if (isInitial || !state.bookings.length) {
        commit("SET_BOOKINGS", bookings);
      } else {
        commit("SET_BOOKINGS", [...state.bookings, ...bookings]);
      }
      return bookings.length;
    }
  },
  async updateAffiliate({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/affiliates`,
        id,
        data
      );
    }
  },
  async createAffiliate({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/affiliates`,
        data
      );
    }
  },
  async exportBookingsToCSV({ rootState, state }) {
    const bookings = await getCollection(
      `/venues/${rootState.venues.selectedVenue.id}/bookings`,
      where("affiliateId", "==", state.editedItem.id)
    );
    const formattedBookings = bookings.map((booking) => {
      const price = getBookingPrice(booking.checkoutInfo);
      return {
        ID: booking.id,
        "Booking date": moment.utc(booking.dateIso).format("MMM D, YYYY"),
        "Paid amount": (price?.paid || 0) / 100,
        "Payment date":
          booking.checkoutInfo?.payments
            .map(
              (payment) =>
                payment.date && moment.utc(payment.date).format("MMM D, YYYY")
            )
            .filter(Boolean)
            .join(", ") || "-",
        "Booking status": booking.status,
        "Commission amount": (+price?.affiliateCommissionAmount || 0) / 100,
      };
    });
    const options = {
      filename: `affiliate_bookings`,
      showLabels: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(formattedBookings);
  },
  async deleteAffiliate({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/affiliates`,
        id
      );
    }
  },
};
