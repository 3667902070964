import {
  deleteDocument,
  getDocById,
  getPaginatedCollection,
} from "@/helpers/firebase-helpers";
import { orderBy, Timestamp, where } from "firebase/firestore";
import BookingService from "@/api/services/BookingService";
import { createDocument } from "@/helpers/firebase-helpers";
import { SignaturesSortEnum } from "@/helpers/enums";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { convert } from "html-to-text";
import { SignatureTypeEnum } from "@/helpers/enums";
import { getTimezoneOffsetHours } from "@/helpers/utils";

const signaturesSortOptionsMap = {
  [SignaturesSortEnum.NEW]: {
    field: "createdAt",
    direction: "desc",
  },
  [SignaturesSortEnum.NEW]: {
    field: "createdAt",
    direction: "desc",
  },
  [SignaturesSortEnum.OLD]: {
    field: "createdAt",
    direction: "asc",
  },
  [SignaturesSortEnum.AZ]: {
    field: "fullName",
    direction: "asc",
  },
  [SignaturesSortEnum.ZA]: {
    field: "fullName",
    direction: "desc",
  },
};

const prepareFieldValue = (value) => {
  if (typeof value === "undefined") return "-";
  else if (Array.isArray(value)) {
    return value.join(", ");
  } else return value;
};

export default {
  async getAllSignaturesWithFilters({ rootState, state }) {
    if (rootState.venues.selectedVenue && rootState.products.selectedProduct) {
      let dateConstraints = [];

      if (state?.periodFilter?.length) {
        let [from, to] = state.periodFilter;

        if (from && !to) {
          to = from;
        }

        const fromOffset = getTimezoneOffsetHours(
          rootState.venues.selectedVenue.timezone,
        );
        const toOffset = getTimezoneOffsetHours(
          rootState.venues.selectedVenue.timezone,
        );

        const fromMoment = moment.utc(from).add(-fromOffset, "h");

        const fromDate = fromMoment?.isValid()
          ? fromMoment.toDate()
          : undefined;

        const toMoment = to
          ? moment.utc(to).endOf("date").add(-toOffset, "h")
          : undefined;

        const toDate = toMoment?.isValid() ? toMoment.toDate() : undefined;

        dateConstraints = [
          !!fromDate && where("createdAt", ">=", fromDate),
          !!toDate && where("createdAt", "<=", toDate),
        ];
      }

      const filterConstraints = [
        state.searchQuery &&
          state.searchField &&
          where(state.searchField, "==", state.searchQuery),
        state.waiverIdFilter && where("waiverId", "==", state.waiverIdFilter),
      ].filter(Boolean);

      const { field, direction } = signaturesSortOptionsMap[state.activeSort];

      let sort = null;

      if (!filterConstraints.length) {
        sort = orderBy(field, direction);
      }

      const limit = 10000; // 10000 is the max firebase value
      let hasMore = true;
      let signatures;
      let currentLastDoc;

      while (hasMore) {
        const { data, lastDoc } = await getPaginatedCollection(
          `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
          currentLastDoc,
          limit,
          ...filterConstraints,
          ...dateConstraints,
          sort,
        );

        const filtredSignatures = state.signatureType
          ? data.filter(
              (item) =>
                (state.signatureType === SignatureTypeEnum.BOOKINGS &&
                  !!item.bookingId) ||
                (state.signatureType === SignatureTypeEnum.WALKINS &&
                  !item.bookingId),
            )
          : data;

        signatures = signatures
          ? [...signatures, ...filtredSignatures]
          : filtredSignatures;

        currentLastDoc = lastDoc;
        hasMore = limit === data.length;
      }

      return signatures;
    }
  },

  async fetchSignatures({ commit, rootState, state }, limitValue = 100) {
    if (rootState.venues.selectedVenue && rootState.products.selectedProduct) {
      let dateConstraints = [];

      if (state?.periodFilter?.length) {
        let [from, to] = state.periodFilter;

        if (from && !to) {
          to = from;
        }

        const fromOffset = getTimezoneOffsetHours(
          rootState.venues.selectedVenue.timezone,
        );
        const toOffset = getTimezoneOffsetHours(
          rootState.venues.selectedVenue.timezone,
        );

        const fromMoment = moment.utc(from).add(-fromOffset, "h");

        const fromDate = fromMoment?.isValid()
          ? fromMoment.toDate()
          : undefined;

        const toMoment = to
          ? moment.utc(to).endOf("date").add(-toOffset, "h")
          : undefined;

        const toDate = toMoment?.isValid() ? toMoment.toDate() : undefined;

        dateConstraints = [
          !!fromDate && where("createdAt", ">=", fromDate),
          !!toDate && where("createdAt", "<=", toDate),
        ];
      }

      const filterConstraints = [
        state.searchQuery &&
          state.searchField &&
          where(state.searchField, "==", state.searchQuery),
        state.waiverIdFilter && where("waiverId", "==", state.waiverIdFilter),
      ].filter(Boolean);

      const { field, direction } = signaturesSortOptionsMap[state.activeSort];
      let sort = null;

      if (!filterConstraints.length) {
        sort = orderBy(field, direction);
      }

      commit("SET_IS_SORT_ENABLED", !!sort);

      const { data: signatures, lastDoc } = await getPaginatedCollection(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        state.lastDoc,
        limitValue,
        ...filterConstraints,
        ...dateConstraints,
        sort,
      );
      if (!lastDoc) return 0;

      const filtredSignatures = state.signatureType
        ? signatures.filter(
            (item) =>
              (state.signatureType === SignatureTypeEnum.BOOKINGS &&
                !!item.bookingId) ||
              (state.signatureType === SignatureTypeEnum.WALKINS &&
                !item.bookingId),
          )
        : signatures;

      if (!state.lastDoc) {
        commit("SET_LIST", filtredSignatures);
      } else {
        commit("SET_LIST", [...state.list, ...filtredSignatures]);
      }

      commit("SET_LAST_DOC", lastDoc);

      return signatures.length;
    } else {
      commit("SET_LIST", []);
      return 0;
    }
  },
  async fetchSignatureById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue && rootState.products.selectedProduct) {
      const signature = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        id,
      );
      const booking =
        signature.bookingId &&
        (await getDocById(
          `/venues/${rootState.venues.selectedVenue.id}/bookings`,
          signature.bookingId,
        ));
      if (booking) {
        signature.bookingRegistrationCode = booking.registrationCode;
      }
      commit("SET_EDITED_ITEM", signature);
    }
  },
  async deleteSignature({ rootState }, id) {
    if (rootState.venues.selectedVenue && rootState.products.selectedProduct) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        id,
      );
    }
  },
  async createSignature({ rootState }, data) {
    if (
      rootState.venues.selectedVenue &&
      rootState.products.selectedProduct &&
      rootState.bookings.editedItem
    ) {
      const fullName = `${data.fields.firstName.value} ${data.fields.lastName.value}`;
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        {
          ...data,
          fullName,
          bookingId: rootState.bookings.editedItem.id,
          createdAt: Timestamp.now(),
        },
      );
    }
  },
  async exportSignaturesToCSV(_, signatures) {
    const signaturesGroupsMap = signatures.reduce((prev, signature) => {
      const key = `${Object.values(signature.fields)
        .map((field) => field.label)
        .sort()}_${signature.checkboxes.map((cb) => convert(cb.text)).sort()}`;
      prev[key] = prev[key] ?? [];
      prev[key].push(signature);
      return prev;
    }, {});
    Object.values(signaturesGroupsMap).forEach((group, index) => {
      const data = group.map((item) => {
        const fields = Object.values(item.fields)
          .filter((field) => !!field.value)
          .reduce(
            (prev, field) => ({
              ...prev,
              [field.label]: prepareFieldValue(field.value),
            }),
            {},
          );

        const checkboxes = item.checkboxes.reduce(
          (prev, cb) => ({
            ...prev,
            [convert(cb.text)]: prepareFieldValue(cb.value),
          }),
          {},
        );

        return {
          "Full Name": item.fullName,
          "Date/time": moment
            .unix(item.createdAt.seconds)
            .toDate()
            .toLocaleString(),
          "Registration code ": item?.bookingReference || "-",
          "Waiver type": item?.bookingId ? "Booking" : "Walk-in",
          ...fields,
          ...checkboxes,
          "Signature file": item?.signature || "-",
        };
      });
      const [dataSample] = data;
      const headers = Object.keys(dataSample);

      const options = {
        filename: `signatures_export${index > 0 ? `_${index}` : ""}`,
        showLabels: true,
        headers,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    });
  },
  resetSignatures({ commit }) {
    commit("SET_LIST", []);
    commit("SET_LAST_DOC", undefined);
  },

  async uploadMedia(_, data) {
    try {
      return await BookingService.uploadMedia(data);
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};
