var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-wrapper"},[_c('transition',{attrs:{"name":"slide-right"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.innerActive || !['xs', 'sm'].includes(_vm.$mq)),expression:"innerActive || !['xs', 'sm'].includes($mq)"}],staticClass:"sidebar",class:{ 'm-collapsed': _vm.isCollapsed }},[(['xs', 'sm'].includes(_vm.$mq))?_c('div',{staticClass:"sidebar-header"},[_c('Icon',{attrs:{"name":"close","is-clickable":""},on:{"click":function($event){_vm.innerActive = false}}}),(_vm.user)?_c('router-link',{staticClass:"user",attrs:{"to":"/settings/profile"},nativeOn:{"click":function($event){_vm.innerActive = false}}},[_c('Avatar',{attrs:{"url":_vm.user.photoURL,"text":_vm.user.displayName}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"menu"},[_c('div',_vm._l((_vm.routes),function(item,index){return _c('div',{key:index},[_c('router-link',_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.isCollapsed ? item.label : '',
                ..._vm.tooltipOptions,
              }),expression:"{\n                content: isCollapsed ? item.label : '',\n                ...tooltipOptions,\n              }",modifiers:{"right":true}}],staticClass:"menu-item",class:{ disabled: item.isDisabled },attrs:{"active-class":"active","to":item.route,"disabled":item.isDisabled},nativeOn:{"click":function($event){return _vm.onMenuItemClick(item)}}},'router-link',item.attributes,false),[_c('Icon',{attrs:{"name":item.icon}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"menu-item-label"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)],1)}),0),(['xs', 'sm'].includes(_vm.$mq))?_c('div',[_c('LogoutButton',{staticClass:"mb-24"})],1):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
            content: _vm.isCollapsed ? 'Expand' : '',
            ..._vm.tooltipOptions,
          }),expression:"{\n            content: isCollapsed ? 'Expand' : '',\n            ...tooltipOptions,\n          }",modifiers:{"right":true}}],staticClass:"menu-item",on:{"click":function($event){_vm.isCollapsed = !_vm.isCollapsed}}},[_c('Icon',{attrs:{"name":_vm.isCollapsed ? 'arrow-right' : 'arrow-left'}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"menu-item-label"},[_vm._v(" Collapse ")])],1)])])]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.innerActive && ['xs', 'sm'].includes(_vm.$mq)),expression:"innerActive && ['xs', 'sm'].includes($mq)"}],staticClass:"overlay",on:{"click":function($event){_vm.innerActive = false}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }