import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

let abortController;

export default class CalendarService extends ServiceBase {
  static populateSlotGroup({ productId, groupId }) {
    const url = `/private/product/${productId}/slot-group/${groupId}/populate`;

    return this.callApi({
      method: "POST",
      url,
      baseURL,
    });
  }

  static updateSlotGroup({ productId, groupId, data }) {
    const url = `/private/product/${productId}/slot-group/${groupId}`;

    return this.callApi({
      method: "PUT",
      url,
      baseURL,
      data,
    });
  }

  static deleteSlotGroup({ productId, groupId }) {
    const url = `/private/product/${productId}/slot-group/${groupId}`;

    return this.callApi({
      method: "DELETE",
      url,
      baseURL,
    });
  }

  static getCalendar({ productIds, from, to, limit = undefined }) {
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    const url = `/private/product/calendar`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params: {
        from,
        to,
        limit,
        productIds,
      },
      signal: abortController.signal,
    });
  }
  static createSlot({ productId, data }) {
    const url = `/private/product/${productId}/slot`;

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
    });
  }
}
