export const RoleEnum = {
  STAFF: "staff",
  MANAGER: "manager",
  OWNER: "owner",
};

export const MailingTemplateToEnum = {
  ...RoleEnum,
  CUSTOMER: "customer",
};

export const MailingTemplateVariableEnum = {
  BOOKING_REGISTRATION_CODE: "BOOKING_REGISTRATION_CODE",
  BOOKING_DATE: "BOOKING_DATE",
  BOOKING_TIME: "BOOKING_TIME",
  BOOKING_TOTAL_PRICE: "BOOKING_TOTAL_PRICE",
  NUMBER_OF_PARTICIPANTS: "NUMBER_OF_PARTICIPANTS",
  MIN_OF_PARTICIPANTS: "MIN_OF_PARTICIPANTS",
  CUSTOMER_FIRSTNAME: "CUSTOMER_FIRSTNAME",
  CUSTOMER_LASTNAME: "CUSTOMER_LASTNAME",
  CUSTOMER_EMAIL: "CUSTOMER_EMAIL",
  CUSTOMER_PHONE: "CUSTOMER_PHONE",
  CUSTOM_MESSAGE: "CUSTOM_MESSAGE",
  PRODUCT_NAME: "PRODUCT_NAME",
  PRODUCT_TITLE: "PRODUCT_TITLE",
  PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
  PRODUCT_CUTOFF_TIME: "PRODUCT_CUTOFF_TIME",
  PRODUCT_IMAGE: "PRODUCT_IMAGE",
  VENUE_NAME: "VENUE_NAME",
  VENUE_ADDRESS: "VENUE_ADDRESS",
  VENUE_PHONE: "VENUE_PHONE",
  VENUE_EMAIL: "VENUE_EMAIL",
  VENUE_LOGO: "VENUE_LOGO",
  VENUE_YOUTUBE: "VENUE_YOUTUBE",
  VENUE_INSTAGRAM: "VENUE_INSTAGRAM",
  VENUE_X: "VENUE_X",
  COMPANY_SITE: "COMPANY_SITE",
  WAIVER_LINK: "WAIVER_LINK",
  COACH_NOTE: "COACH_NOTE",
  DEPOSIT_AMOUNT: "DEPOSIT_AMOUNT",
  DEPOSIT_PERSONS: "DEPOSIT_PERSONS",
  TAXES: "TAXES",
  CUSTOMER_PAID: "CUSTOMER_PAID",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  LINES: "LINES",
  LANES_COUNT: "LANES_COUNT",
  GROUP_MINIMUM: "GROUP_MINIMUM",
  GROUP_MAXIMUM: "GROUP_MAXIMUM",
  RESOURCES_CAPACITY: "RESOURCES_CAPACITY",
  RESOURCES_MIN_CAPACITY: "RESOURCES_MIN_CAPACITY",
  SLOT_GROUP_LABEL: "SLOT_GROUP_LABEL",
};

export const EndRepeatEnum = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  SIX: 6,
  NINE: 9,
  TWELVE: 12,
};

export const RangeButtonsEnum = {
  YESTERDAY: "yesterday",
  TODAY: "today",
  TOMORROW: "tomorrow",
  WEEK: "week",
  MONTH: "month",
  THIS_WEEK: "thisWeek",
  THIS_MONTH: "thisMonth",
  YEAR: "year",
  THIS_YEAR: "thisYear",
  CUSTOM: "custom",
};

export const BookingStatusEnum = {
  NEW: "new",
  WAIT_FOR_PAYMENT: "wait_for_payment",
  PAID: "paid",
  CANCELED: "canceled",
  ABANDONED: "abandoned",
};

export const BookingMailTypesEnum = {
  CONFIRMATION: "confirmation",
  CANCELLATION: "cancellation",
  REMINDER: "reminder",
  ABANDONED: "abandoned",
  CUSTOM: "custom",
};

// eslint-disable-next-line no-unused-vars
const { NEW, CANCELED, ...BookingStatusWithoutNewEnum } = BookingStatusEnum;

export const BookingFilterEnum = {
  ...BookingStatusWithoutNewEnum,
  UPCOMING: "upcoming",
  SAVED: "saved",
  ENDED: "ended",
  CANCELED: "canceled",
  WARNING: "warning",
  REFUNDED: "refunded",
};

export const BookingPricingTypeEnum = {
  PERSON: "person",
  GROUP_RANGE: "group-range",
};

export const SignaturesSortEnum = {
  NEW: "new",
  OLD: "old",
  AZ: "az",
  ZA: "za",
};

export const SignaturesSearchFieldEnum = {
  PHONE: "fields.phone.value",
  EMAIL: "fields.email.value",
  FIRST_NAME: "fields.firstName.value",
  LAST_NAME: "fields.lastName.value",
  BOOKING_REFERENCE: "bookingReference",
};

export const SignatureTypeEnum = {
  WALKINS: "walkins",
  BOOKINGS: "bookings",
};

export const VenueStatusEnum = {
  INACTIVE: "inactive",
  PENDING: "pending",
  LIVE: "live",
};

export const CustomFieldTypeEnum = {
  TEXT: "text",
  EMAIL: "email",
  DATE: "date",
  PHONE: "phone",
  SELECT: "select",
  NUMERIC: "numeric",
  MULTIPLE: "multiple",
  FLAG: "flag",
};

export const StandardWaiverFieldsEnum = {
  FIRSTNAME: "firstName",
  LASTNAME: "lastName",
  ADDRESS: "address",
  PHONE: "phone",
  EMAIL: "email",
  BIRTHDATE: "birthdate",
};

export const StripeBankAccountStatusEnum = {
  ACTIVE: "active",
  PENDING: "pending",
  INACTIVE: "inactive",
};

export const LogTypeEnum = {
  REFUND: "refund",
  CANCEL: "cancel",
  CUSTOM: "custom",
  UPDATE: "update",
  PAID: "paid",
  CREATE: "create",
  WARNING: "warning",
  ABANDONED: "abandoned",
};

export const UsersSortEnum = {
  AZ: "az",
  ZA: "za",
};

export const DashboardStatsEnum = {
  GROSS_REVENUE: "grossRevenue",
  REFUNDED_AMOUNT: "refundedAmount",
  NET_REVENUE: "netRevenue",
  TAXES_AMOUNT: "taxesAmount",
  BOOKINGS_COUNT: "bookingsCount",
  CANCELLED_BOOKING_COUNT: "canceledBookingsCount",
  REFUNDED_BOOKINGS_COUNT: "refundedBookingsCount",
  BOOKING_SOURCE: "bookingSource",
  BOUNCE_RATE: "bounceRate",
  OCCUPANCY_RATE: "occupancyRate",
};

export const BookingInteractionEnum = {
  OPEN: 0,
  SELECT_PRODUCT: 1,
  PLAYERS_COUNT: 2,
  SELECT_SLOTS: 3,
  REQUEST_PAYMENT: 4,
  PAYMENT_SUCCESS: 5,
};

export const PresenceStatusEnum = {
  FULLY: "fully",
  PARTIALLY: "partially",
  NO: "no",
};

export const BookingSearchFieldEnum = {
  REGISTRATION_CODE: "registrationCode",
  PHONE: "reservationInfo.phone",
  EMAIL: "reservationInfo.email",
  FIRST_NAME: "reservationInfo.firstName",
  LAST_NAME: "reservationInfo.lastName",
};

export const WidgetThemeEnum = {
  YOREFEREE: "yoreferee",
  BADAXE: "badaxe",
  YOREFEREE2: "yoreferee2",
};

export const BookingStatusPageEnum = {
  UPCOMING: "upcoming",
  SAVED: "saved",
  ABANDONED: "abandoned",
  ENDED: "ended",
  CANCELED: "canceled",
};

export const BookingPageViewEnum = {
  DETAILED: "detailed",
  COMPACT: "compact",
  TABLE: "table",
};

export const BookingTableSortFieldEnum = {
  CREATION_DATE: "createdAt",
  EVENT_DATE: "date",
};

export const SortDirectionEnum = {
  DESC: "desc",
  ASC: "asc",
};

export const WidgetPositionEnum = {
  EMBEDDED: "embedded",
  FLOATING: "floating",
};

export const PromoRateTypeEnum = {
  FIXED: "fixed",
  PERCENT: "percent",
};

export const TaxBehaviourEnum = {
  BEFORE: "before",
  AFTER: "after",
};

export const WidgetViewColorEnum = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  AVAILABILITY_LOW: "availability-low",
  AVAILABILITY_MODERATE: "availability-moderate",
  AVAILABILITY_HIGH: "availability-high",
};

export const MoneyFormatVariablesEnum = {
  AMOUNT: "amount",
  AMOUNT_ROUNDED: "amount_rounded",
};
