import store from "@/store";
import { StripeBankAccountStatusEnum } from "@/helpers/enums";

export const onboardVenuePathName = "OnboardVenue";
export const onboardBankAccountPathName = "OnboardBankAccount";

export const handleOnboard = async (route, redirect) => {
  const onboardStep = (flag, pathName) => {
    let interrupt = false;
    if (!flag) {
      if (route.name !== pathName) {
        redirect({
          name: pathName,
        });
        interrupt = true;
      }
      interrupt = true;
    } else {
      if (flag && route.name === pathName) {
        redirect("/");
        interrupt = true;
      }
    }
    return interrupt;
  };

  const venue = store.state.venues.selectedVenue;
  // Register the venue
  if (onboardStep(venue?.id, onboardVenuePathName)) return;
  // Add bank account
  onboardStep(
    store.state.users.isSkipBankAccountSetup ||
      (venue?.stripeBankAccountStatus &&
        venue.stripeBankAccountStatus !== StripeBankAccountStatusEnum.INACTIVE),
    onboardBankAccountPathName
  );
};
