import store from "@/store";
import { auth } from "@/plugins/firebase";
import { handleOnboard } from "@/helpers/onboard";

export const onboardMiddleware = async (to, from, next) => {
  const user = auth.currentUser;
  const loggedIn = !!user && !user.disabled;
  if (loggedIn && !store.state.isGlobalLoading) {
    const redirected = await handleOnboard(to, next);
    if (redirected) return;
  }
  return next();
};
