import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  startAfter,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "@/plugins/firebase";
import {
  GoogleAuthProvider,
  EmailAuthProvider,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
  signInWithPopup,
  signOut,
  updateEmail,
  updateProfile,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import router from "@/router";
import alert from "@/plugins/alert";
import passwordDialog from "@/components/auth/PasswordDialog";

// Auth
export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
  await router.push("/");
};

export const loginWithCredentials = async ({ email, password, rememberMe }) => {
  await signInWithEmailAndPassword(auth, email, password);
  await setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence,
  );
  await router.push("/");
};

// export const signUpWithCredentials = async ({
//   email,
//   password,
//   displayName,
// }) => {
//   await createUserWithEmailAndPassword(auth, email, password);
//   await updateUser({ displayName });
// };

export const restorePassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const updateUser = async (payload) => {
  const { displayName, email, photoURL } = payload;
  const user = auth.currentUser;
  const updateProfileData = {};
  if (displayName && user.displayName !== displayName) {
    updateProfileData.displayName = displayName;
  }
  if (photoURL !== user.photoURL) {
    updateProfileData.photoURL = photoURL;
  }
  const runOperations = async () => {
    await Promise.all(
      [
        Object.keys(updateProfileData).length
          ? updateProfile(user, updateProfileData)
          : [],
        email && user.email !== email ? updateEmail(user, email) : [],
      ].flat(),
    );
  };
  try {
    await runOperations();
  } catch {
    const hasGoogleProvider = user.providerData.some(
      (provider) => provider.providerId === "google.com",
    );
    const hasPasswordProvider = user.providerData.some(
      (provider) => provider.providerId === "password",
    );
    if (hasGoogleProvider) {
      await reauthenticateWithPopup(user, new GoogleAuthProvider());
    } else if (hasPasswordProvider) {
      const password = await passwordDialog.open();
      if (password) {
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);
      }
    }
    try {
      await runOperations();
    } catch (err) {
      alert.open({
        message:
          "An error occurred while updating profile data. Try again later",
      });
    }
  }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  const user = auth.currentUser;
  const hasGoogleProvider = user.providerData.some(
    (provider) => provider.providerId === "google.com",
  );
  const hasPasswordProvider = user.providerData.some(
    (provider) => provider.providerId === "password",
  );
  try {
    if (hasPasswordProvider) {
      await reauthenticateWithCredential(
        user,
        EmailAuthProvider.credential(user.email, oldPassword),
      );
    } else if (hasGoogleProvider) {
      await reauthenticateWithPopup(user, new GoogleAuthProvider());
    }
    await updatePassword(user, newPassword);
  } catch {
    await alert.open({
      message:
        "Password change failed. Double check old password and try again",
      variant: "danger",
    });
    return false;
  }
  await reauthenticateWithCredential(
    user,
    EmailAuthProvider.credential(user.email, newPassword),
  );
  return true;
};

export const logout = async () => {
  await signOut(auth);
  await router.push({
    name: "Login",
  });
};

// Data
const getErrorMessage = (action, collectionPath) => {
  const chunks = collectionPath.split("/");
  const collectionName = chunks[chunks.length - 1]
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? " " : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("");
  return `An error occurred while ${action} ${collectionName}. Try again later`;
};

export const getDocById = async (collectionPath, docId) => {
  try {
    const docSnap = await getDoc(doc(db, collectionPath, docId));
    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data(),
      };
    }
    return null;
  } catch (err) {
    alert.open({
      message: getErrorMessage("receiving one of the ", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};

export const getCollection = async (collectionPath, ...queryParams) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionPath),
        ...queryParams.filter((par) => par),
      ),
    );
    return querySnapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
    }));
  } catch (err) {
    alert.open({
      message: getErrorMessage("receiving list of the", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};

export const getPaginatedCollection = async (
  collectionPath,
  lastDoc,
  limitValue,
  ...queryParams
) => {
  try {
    const queries = [lastDoc && startAfter(lastDoc), limit(limitValue)];
    const querySnapshot = await getDocs(
      query(
        collection(db, collectionPath),
        ...queryParams.filter((q) => q),
        ...queries.filter((q) => q),
      ),
    );

    return {
      data: querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      })),
      lastDoc: querySnapshot.docs[querySnapshot.size - 1],
    };
  } catch (err) {
    console.log("err > ", err);
    alert.open({
      message: getErrorMessage("receiving list of the", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};

export const updateDocument = (collectionPath, docId, payload) => {
  try {
    return updateDoc(doc(db, collectionPath, docId), payload);
  } catch (err) {
    alert.open({
      message: getErrorMessage("updating one of the", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};

export const createDocument = (collectionPath, payload) => {
  try {
    return addDoc(collection(db, collectionPath), payload);
  } catch (err) {
    alert.open({
      message: getErrorMessage("creating one of the", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};

export const deleteDocument = (collectionPath, docId) => {
  try {
    return deleteDoc(doc(db, collectionPath, docId));
  } catch (err) {
    alert.open({
      message: getErrorMessage("deleting one of the", collectionPath),
      variant: "danger",
    });
    throw err;
  }
};
