import {
  RoleEnum,
  StandardWaiverFieldsEnum,
  CustomFieldTypeEnum,
  MailingTemplateVariableEnum,
  DashboardStatsEnum,
  PresenceStatusEnum,
  BookingSearchFieldEnum,
  VenueStatusEnum,
  SignaturesSearchFieldEnum,
  WidgetViewColorEnum,
  BookingMailTypesEnum,
} from "@/helpers/enums";

export const STANDARD_WAIVER_FIELDS_LABELS_MAP = {
  [StandardWaiverFieldsEnum.FIRSTNAME]: "First Name",
  [StandardWaiverFieldsEnum.LASTNAME]: "Last Name",
  [StandardWaiverFieldsEnum.ADDRESS]: "Address",
  [StandardWaiverFieldsEnum.BIRTHDATE]: "Birthdate",
  [StandardWaiverFieldsEnum.EMAIL]: "Email address",
  [StandardWaiverFieldsEnum.PHONE]: "Phone number",
};

export const STANDARD_WAIVER_FIELDS_TYPES_MAP = {
  [StandardWaiverFieldsEnum.FIRSTNAME]: CustomFieldTypeEnum.TEXT,
  [StandardWaiverFieldsEnum.LASTNAME]: CustomFieldTypeEnum.TEXT,
  [StandardWaiverFieldsEnum.ADDRESS]: CustomFieldTypeEnum.TEXT,
  [StandardWaiverFieldsEnum.BIRTHDATE]: CustomFieldTypeEnum.DATE,
  [StandardWaiverFieldsEnum.EMAIL]: CustomFieldTypeEnum.EMAIL,
  [StandardWaiverFieldsEnum.PHONE]: CustomFieldTypeEnum.PHONE,
};

export const NON_DELETABLE_STANDARD_WAIVER_FIELDS = [
  StandardWaiverFieldsEnum.FIRSTNAME,
  StandardWaiverFieldsEnum.LASTNAME,
  StandardWaiverFieldsEnum.EMAIL,
];

export const ROLE_NAMES_MAP = {
  [RoleEnum.OWNER]: "Owner",
  [RoleEnum.MANAGER]: "Manager",
  [RoleEnum.STAFF]: "Staff",
};

export const MAILING_TEMPLATE_VARIABLES_NAMES_MAP = {
  [MailingTemplateVariableEnum.BOOKING_REGISTRATION_CODE]:
    "Booking registration code",
  [MailingTemplateVariableEnum.BOOKING_DATE]: "Booking date",
  [MailingTemplateVariableEnum.BOOKING_TIME]: "Booking time",
  [MailingTemplateVariableEnum.NUMBER_OF_PARTICIPANTS]:
    "Number of participants",
  [MailingTemplateVariableEnum.MIN_OF_PARTICIPANTS]: "Minimum of participants",
  [MailingTemplateVariableEnum.PRODUCT_NAME]: "Product name",
  [MailingTemplateVariableEnum.PRODUCT_TITLE]: "Product title",
  [MailingTemplateVariableEnum.PRODUCT_DESCRIPTION]: "Product description",
  [MailingTemplateVariableEnum.PRODUCT_CUTOFF_TIME]: "Product cutoff time",
  [MailingTemplateVariableEnum.PRODUCT_IMAGE]: "Product image",
  [MailingTemplateVariableEnum.VENUE_NAME]: "Venue name",
  [MailingTemplateVariableEnum.VENUE_ADDRESS]: "Venue address",
  [MailingTemplateVariableEnum.VENUE_PHONE]: "Venue phone",
  [MailingTemplateVariableEnum.VENUE_EMAIL]: "Venue email",
  [MailingTemplateVariableEnum.VENUE_LOGO]: "Venue logo",
  [MailingTemplateVariableEnum.VENUE_YOUTUBE]: "Venue Youtube",
  [MailingTemplateVariableEnum.VENUE_INSTAGRAM]: "Venue Instagram",
  [MailingTemplateVariableEnum.VENUE_X]: "Venue X",
  [MailingTemplateVariableEnum.COMPANY_SITE]: "Venue company site",
  [MailingTemplateVariableEnum.CUSTOMER_FIRSTNAME]: "Customer first name",
  [MailingTemplateVariableEnum.CUSTOMER_LASTNAME]: "Customer last name",
  [MailingTemplateVariableEnum.CUSTOMER_EMAIL]: "Customer email",
  [MailingTemplateVariableEnum.CUSTOMER_PHONE]: "Customer phone",
  [MailingTemplateVariableEnum.BOOKING_TOTAL_PRICE]: "Booking total price",
  [MailingTemplateVariableEnum.WAIVER_LINK]: "Waiver link",
  [MailingTemplateVariableEnum.COACH_NOTE]: "Coach note",
  [MailingTemplateVariableEnum.CUSTOM_MESSAGE]: "Custom message",
  [MailingTemplateVariableEnum.DEPOSIT_AMOUNT]: "Deposited amount",
  [MailingTemplateVariableEnum.DEPOSIT_PERSONS]: "Deposited persons",
  [MailingTemplateVariableEnum.TAXES]: "Taxes",
  [MailingTemplateVariableEnum.CUSTOMER_PAID]: "Customer paid",
  [MailingTemplateVariableEnum.PAYMENT_METHOD]: "Payment method",
  [MailingTemplateVariableEnum.LINES]: "Lanes",
  [MailingTemplateVariableEnum.LANES_COUNT]: "Number of lanes",
  [MailingTemplateVariableEnum.GROUP_MINIMUM]: "Group minimum",
  [MailingTemplateVariableEnum.GROUP_MAXIMUM]: "Group maximum",
  [MailingTemplateVariableEnum.RESOURCES_CAPACITY]: "Resources capacity",
  [MailingTemplateVariableEnum.RESOURCES_MIN_CAPACITY]:
    "Resources min capacity",
  [MailingTemplateVariableEnum.SLOT_GROUP_LABEL]: "Slot group label",
};

export const DASHBOARD_STATS_LABELS_MAP = {
  [DashboardStatsEnum.GROSS_REVENUE]: "Gross revenue",
  [DashboardStatsEnum.REFUNDED_AMOUNT]: "Refunded amount",
  [DashboardStatsEnum.NET_REVENUE]: "Net revenue",
  [DashboardStatsEnum.TAXES_AMOUNT]: "Taxes amount",
  [DashboardStatsEnum.BOOKINGS_COUNT]: "Total number of bookings",
  [DashboardStatsEnum.CANCELLED_BOOKING_COUNT]: "Number of cancelled bookings",
  [DashboardStatsEnum.REFUNDED_BOOKINGS_COUNT]: "Number of refunded bookings",
  [DashboardStatsEnum.BOOKING_SOURCE]: "Booking source",
  [DashboardStatsEnum.OCCUPANCY_RATE]: "Occupancy rate",
};

export const ENTITY_NAMES_MAP = {
  bookings: "Bookings",
  calendar: "Calendar",
  resources: "Lane management",
  products: "Products",
  waivers: "Waivers",
  signatures: "Signatures",
  users: "Users",
  profile: "Profile",
  venues: "Venue",
  bankAccounts: "Bank accounts",
  taxes: "Taxes",
  promocodes: "Promocodes",
  affiliates: "Affiliates",
  upsellItems: "Upsell merchandise",
  terms: "Terms & conditions",
  mailingTemplates: "Mailing templates",
  integrations: "Integrations",
  widgetTemplates: "Widget templates",
};

export const ACTION_NAMES_MAP = {
  integrations: {
    create: "Create integration",
    edit: "Edit integration",
    delete: "Delete integration",
  },
  bookings: {
    create: "Create booking",
    edit: "Edit booking",
    cancel: "Cancel booking",
    refund: "Refund booking",
    collect: "Collect booking payments",
    setCustomPrice: "Set custom booking price",
    sendEmail: "Manually send emails about booking",
    save: "Add booking to saved",
    addNote: "Add note to booking",
    changePresenceStatus: "Change presence status",
    delete: "Delete booking",
  },
  calendar: {
    create: "Create availability",
    edit: "Edit availability or slot",
    delete: "Delete availability or slot",
    setNonWorkingDays: "Set blackout dates",
  },
  resources: {
    create: "Create lane",
    edit: "Edit lane",
    delete: "Delete lane",
    pause: "Pause lane",
  },
  products: {
    create: "Create product",
    edit: "Edit product",
    delete: "Delete product",
    pause: "Pause product",
  },
  waivers: {
    create: "Create waiver",
    edit: "Edit waiver",
    delete: "Delete waiver",
  },
  signatures: {
    create: "Create signature by signing waiver ",
    delete: "Delete signature",
  },
  users: {
    create: "Add user to venue",
    delete: "Remove user from venue",
    edit: "Edit user's profile and role",
  },
  profile: {
    edit: "Edit own profile",
    changePassword: "Change own password",
  },
  venues: {
    edit: "Edit venue",
  },
  bankAccounts: {
    view: "View bank accounts",
    create: "Add new bank account",
    delete: "Delete bank account",
    setDefault: "Set bank account as default",
  },
  taxes: {
    create: "Create tax",
    delete: "Delete tax",
    edit: "Edit tax",
  },
  promocodes: {
    create: "Create promocode",
    delete: "Delete promocode",
    edit: "Edit promocode",
  },
  affiliates: {
    create: "Create promocode",
    delete: "Delete promocode",
    edit: "Edit promocode",
  },
  upsellItems: {
    create: "Create upsell merchandise",
    edit: "Edit upsell merchandise",
    delete: "Delete upsell merchandise",
    pause: "Pause upsell merchandise",
  },
  terms: {
    create: "Create terms & conditions",
    edit: "Edit terms & conditions",
    delete: "Delete terms & conditions",
  },
  mailingTemplates: {
    create: "Create mailing template",
    edit: "Edit mailing template",
    delete: "Delete mailing template",
    pause: "Pause mailing template",
  },
  widgetTemplates: {
    create: "Create widget template",
    edit: "Edit widget template",
    delete: "Delete widget template",
  },
};

export const DashboardStatsConfig = {
  [DashboardStatsEnum.GROSS_REVENUE]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "money",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.GROSS_REVENUE],
      variant: "success",
    },
    tooltip:
      "Amount of money earned from all bookings during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.REFUNDED_AMOUNT]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "money",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.REFUNDED_AMOUNT],
      variant: "danger",
    },
    tooltip: "Amount of funds returned during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.NET_REVENUE]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "money",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.NET_REVENUE],
      variant: "valueDepending",
    },
    tooltip: "Difference between gross revenue and refunded amount",
    isClickable: true,
  },
  [DashboardStatsEnum.BOUNCE_RATE]: {
    component: "DashboardBounceRate",
    columnSpan: 2,
    rowSpan: 2,
    tooltip:
      'Bounce rate is defined as the percentage of visitors who opened the widget, but did not complete the booking process, "bouncing" at some stage (always filtered by event date)',
  },
  [DashboardStatsEnum.TAXES_AMOUNT]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "money",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.TAXES_AMOUNT],
      variant: "danger",
    },
    tooltip: "Amount of taxes collected during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.BOOKINGS_COUNT]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "number",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.BOOKINGS_COUNT],
    },
    tooltip: "Number of bookings made during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.CANCELLED_BOOKING_COUNT]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "number",
      label:
        DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.CANCELLED_BOOKING_COUNT],
    },
    tooltip: "Number of bookings canceled during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.REFUNDED_BOOKINGS_COUNT]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "number",
      label:
        DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.REFUNDED_BOOKINGS_COUNT],
    },
    tooltip: "Number of bookings refunded during a selected period",
    isClickable: true,
  },
  [DashboardStatsEnum.OCCUPANCY_RATE]: {
    component: "DashboardNumeric",
    componentProps: {
      type: "percent",
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.OCCUPANCY_RATE],
    },
    tooltip:
      "Occupancy rate is defined as the ratio of taken resources to all available resources (always filtered by booking date)",
    isClickable: true,
  },
  [DashboardStatsEnum.BOOKING_SOURCE]: {
    component: "DashboardDoughnut",
    componentProps: {
      label: DASHBOARD_STATS_LABELS_MAP[DashboardStatsEnum.BOOKING_SOURCE],
      config: {
        labels: ["Online", "Phone", "Walk-in"],
        datasets: [
          {
            label: "Booking source",
            data: [],
            backgroundColor: ["#286EED", "#F8D267", "#C2CEE3"],
            hoverOffset: 4,
          },
        ],
      },
    },
    formatter: (val) => [val?.widget || 0, val?.admin || 0, val?.walkin || 0],
    tooltip:
      "A chart showing the ratio between bookings made online, via phone or as walk-in",
  },
};

export const PRESENCE_STATUS_LABELS_MAP = {
  [PresenceStatusEnum.NO]: "No show",
  [PresenceStatusEnum.PARTIALLY]: "Partially Checked In",
  [PresenceStatusEnum.FULLY]: "Checked In",
};

export const PresenceStatusOptions = [
  {
    value: PresenceStatusEnum.NO,
    name: PRESENCE_STATUS_LABELS_MAP[PresenceStatusEnum.NO],
    variant: "danger",
  },
  {
    value: PresenceStatusEnum.PARTIALLY,
    name: PRESENCE_STATUS_LABELS_MAP[PresenceStatusEnum.PARTIALLY],
    variant: "warning",
  },
  {
    value: PresenceStatusEnum.FULLY,
    name: PRESENCE_STATUS_LABELS_MAP[PresenceStatusEnum.FULLY],
    variant: "success",
  },
];

export const BookingSearchFieldOptions = [
  {
    value: BookingSearchFieldEnum.REGISTRATION_CODE,
    name: "Registration code",
    min: 1,
  },
  {
    value: BookingSearchFieldEnum.PHONE,
    name: "Phone",
    min: 17,
    mask: "+1 (###) ###-####",
  },
  {
    value: BookingSearchFieldEnum.EMAIL,
    name: "Email",
    min: 4,
  },
  {
    value: BookingSearchFieldEnum.FIRST_NAME,
    name: "Customer first name",
    min: 1,
  },
  {
    value: BookingSearchFieldEnum.LAST_NAME,
    name: "Customer last name",
    min: 1,
  },
];

export const SignaturesSearchFieldOptions = [
  {
    value: SignaturesSearchFieldEnum.PHONE,
    name: "Phone",
    min: 17,
    mask: "+1 (###) ###-####",
  },
  {
    value: SignaturesSearchFieldEnum.EMAIL,
    name: "Email",
    min: 4,
  },
  {
    value: SignaturesSearchFieldEnum.FIRST_NAME,
    name: "First name",
    min: 1,
  },
  {
    value: SignaturesSearchFieldEnum.LAST_NAME,
    name: "Last name",
    min: 1,
  },
  {
    value: SignaturesSearchFieldEnum.BOOKING_REFERENCE,
    name: "Booking reference",
    min: 3,
  },
];

export const DEFAULT_PAYMENT_METHODS = [
  { name: "Cash", isActive: true },
  { name: "Square", isActive: true },
];

export const VENUE_STATUS_NAME_MAP = {
  [VenueStatusEnum.INACTIVE]: "Inactive",
  [VenueStatusEnum.PENDING]: "Pending for approval",
  [VenueStatusEnum.LIVE]: "Live",
};

export const WIDGET_VIEW_COLORS_NAME_MAP = {
  [WidgetViewColorEnum.PRIMARY]: "Primary color",
  [WidgetViewColorEnum.SECONDARY]: "Secondary color",
};

export const WIDGET_VIEW_AVAILABILITY_COLORS_NAME_MAP = {
  [WidgetViewColorEnum.AVAILABILITY_HIGH]: "High",
  [WidgetViewColorEnum.AVAILABILITY_MODERATE]: "Moderate",
  [WidgetViewColorEnum.AVAILABILITY_LOW]: "Low",
};

export const DEFAULT_MONEY_FORMAT = "${{amount}}";

export const WEEKDAYS_SHORT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const templateEventTypes = [
  {
    value: BookingMailTypesEnum.CONFIRMATION,
    name: "Booking confirmation",
  },
  {
    value: BookingMailTypesEnum.REMINDER,
    name: "Booking reminder",
  },
  {
    value: BookingMailTypesEnum.CANCELLATION,
    name: "Booking cancellation",
  },
  {
    value: BookingMailTypesEnum.ABANDONED,
    name: "Booking abandoned",
  },
  {
    value: BookingMailTypesEnum.CUSTOM,
    name: "Custom template",
  },
];
