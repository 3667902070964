<template>
  <LogoSvg
    class="logo"
    :style="{ width: `${width}px`, height: `${height}px` }"
    alt="Yoreferee Booking Engine"
  />
</template>

<script>
import LogoSvg from "@/assets/images/logo.svg";

export default {
  name: "Logo",
  components: {
    LogoSvg,
  },
  props: {
    width: {
      type: Number,
      default: 98,
    },
    height: {
      type: Number,
      default: 38,
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
