import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

let abortController;
let listAbortController;
let chartAbortController;

export default class StatisticsService extends ServiceBase {
  static getStatistics(params) {
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    const url = "/private/statistics";

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: abortController.signal,
    });
  }

  static getStatisticDetailsList(statKey, params) {
    if (listAbortController) {
      listAbortController.abort();
    }
    listAbortController = new AbortController();

    const url = `/private/statistics/${statKey}/list`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: listAbortController.signal,
    });
  }

  static getStatisticDetailsChart(statKey, params) {
    if (chartAbortController) {
      chartAbortController.abort();
    }
    chartAbortController = new AbortController();

    const url = `/private/statistics/${statKey}/chart`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: chartAbortController.signal,
    });
  }
}
