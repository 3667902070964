export default {
  SET_STATISTICS(state, statistics) {
    state.statistics = statistics;
  },
  SET_LAST_FETCHED_AT(state, lastFetchedAt) {
    state.lastFetchedAt = lastFetchedAt;
  },
  SET_DETAILS_LIST(state, list) {
    state.detailsList = list;
  },
  SET_DETAILS_CHART(state, chart) {
    state.detailsChart = chart;
  },
  SET_PRODUCT_FILTER_ID(state, productId) {
    state.productFilterId = productId;
  },
  SET_DATE_RANGE_FILTER(state, dateRange) {
    state.dateRangeFilter = dateRange;
  },
  SET_PERIOD_FILTER(state, period) {
    state.periodFilter = period;
  },
  SET_DATE_FIELD(state, dateField) {
    state.dateField = dateField;
  },
  SET_STRIPE_BALANCE(state, balance) {
    state.stripeBalance = balance;
  },
  SET_PAYOUTS(state, payouts) {
    state.payouts = payouts;
  },
  SET_LAST_PAYOUT_ID(state, id) {
    state.lastPayoutId = id;
  },
};
