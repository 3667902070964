import { deleteDocument, getCollection } from "@/helpers/firebase-helpers";
import { doc, runTransaction, where } from "firebase/firestore";
import { db } from "@/plugins/firebase";
import UsersService from "@/api/services/UsersService";
import { RoleEnum } from "@/helpers/enums";

export default {
  async fetchVenues({ commit, state }) {
    const queryConstraints = state.statusFilter
      ? [where("status", "==", state.statusFilter)]
      : [];
    const venues = await getCollection("venues", ...queryConstraints);
    commit("SET_LIST", venues);
  },
  async createVenueForOwner(_, { id, data }) {
    const { ownerEmail, ...restData } = data;
    if (ownerEmail) {
      await runTransaction(db, async (transaction) => {
        let exists;
        try {
          const venueSnap = await transaction.get(doc(db, "venues", id));
          exists = venueSnap.exists();
        } catch (ex) {
          exists = false;
        }

        if (exists) {
          throw `Venue with ID '${id}' already exists`;
        }

        transaction.set(doc(db, "venues", id), restData);
      });
      try {
        await UsersService.addUser(
          { email: ownerEmail, role: RoleEnum.OWNER },
          id
        );
      } catch {
        await deleteDocument("venues", id);
      }
    }
  },
};
