import moment from "moment";
import { getDate } from "@/helpers/utils";
import { CALENDAR_VIEW_TYPE_ENUM } from "@/helpers/mocks";

export default {
  getCurrentWeek: (state, _, rootState) => {
    const date = state.date;
    const dayOfWeek = moment(date).day();
    const firstDayOfWeek = rootState.venues.selectedVenue?.firstDayOfWeek || 0;
    let index = 0;
    const week = [];

    while (index < 7) {
      let currentDate = getDate(date, false)
        .add(index - dayOfWeek + firstDayOfWeek, "d")
        .format("YYYY-MM-DD");
      week.push({
        date: currentDate,
      });
      index++;
    }

    return week;
  },
  getMonth: (state) => {
    return state.view === CALENDAR_VIEW_TYPE_ENUM.MONTH
      ? moment(state.month).format("MMMM")
      : getDate(state.date, false).format("MMMM");
  },
  getCalendarMonth: (state) => {
    if (!state.calendar.dates) return [];

    return Object.entries(state.calendar.dates).map(([key, value]) => ({
      date: key,
      isNonWorking: !value.isWorking,
      slots: value.list,
      total: value.total,
    }));
  },
};
