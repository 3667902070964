import router from "@/router";
import store from "@/store";
import { eventBus } from "@/helpers/eventBus";
import { app as vueApp } from "@/main";

const goToRouteBeforeStep = (routeName, modalName) => ({
  before() {
    return new Promise((resolve) => {
      const callback = () => {
        if (modalName) {
          setTimeout(() => {
            vueApp.$modal.show(modalName);
            resolve();
          }, 500);
        } else {
          vueApp.$modal.hideAll();
          resolve();
        }
      };
      if (router.currentRoute.name !== routeName) {
        return router.push({ name: routeName }).then(callback);
      }
      callback();
    });
  },
});

const waitForRoute = (routeName, backRouteName) => ({
  waitForAction() {
    return new Promise((resolve) => {
      if (router.currentRoute.name === routeName) {
        resolve();
      } else {
        this.resolveAction = (toRoute, nextFn) => {
          unregisterGuard();
          if (toRoute?.name === routeName) {
            resolve();
          }
          nextFn && nextFn();

          return true;
        };
        const unregisterGuard = router.beforeEach((to, from, next) => {
          if (to.name === routeName || to.name === backRouteName) {
            return this.resolveAction(to, next);
          }
          return false;
        });
      }
    });
  },
});

const waitForStoreAction = (actionName, isSkippable = true) => ({
  isSkippable,
  waitForAction() {
    return new Promise((resolve) => {
      this.resolveAction = () => {
        unsubscribe();
        resolve();
      };
      const unsubscribe = store.subscribeAction({
        after: (action) => {
          if (action.type === actionName) {
            this.resolveAction();
          }
        },
      });
    });
  },
});

const waitForEvent = (eventName) => ({
  waitForAction() {
    return new Promise((resolve) => {
      eventBus.$on(eventName, () => {
        eventBus.$off(eventName);
        resolve();
      });
    });
  },
});

export const getOnboardingGuideSteps = (name) =>
  [
    // 0
    {
      title: `Welcome, ${name}!`,
      content:
        "Welcome to YoReferee. Let’s setup your dashboard so you’re ready to take your first bookings.",
      params: {
        highlight: false,
        enableScrolling: false,
      },
      isFixed: true,
      ...goToRouteBeforeStep("Dashboard"),
    },
    // 1
    {
      title: "Settings",
      content:
        "Let’s navigate to the Settings tab where you will input your T&C, Taxes, and Upsells.",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Dashboard"),
      ...waitForRoute("Settings"),
    },
    // 2
    {
      title: "Terms and conditions",
      content: "To get started, go to the Terms and conditions section.",
      params: {
        placement: "top",
      },
      ...goToRouteBeforeStep("Settings"),
      ...waitForRoute("Terms", "Dashboard"),
    },
    // 3
    {
      title: "Terms and conditions",
      content:
        "These are your general terms, it will be visible to the customer before they complete their booking. You can write a new one or paste in an existing one into text box.",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Terms"),
      ...waitForRoute("CreateTerms", "Settings"),
    },
    // 4
    {
      title: "Terms and conditions",
      content: "Please create terms",
      params: {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("CreateTerms"),
      ...waitForStoreAction("terms/createTerms"),
    },
    // 5
    {
      title: "Terms and conditions",
      content:
        "That's it! You successfully created Terms and conditions for your future products",
      params: {
        placement: "top",
        enableScrolling: false,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Terms"),
    },
    // 6
    {
      title: "Taxes",
      content: "To continue, go to the Taxes section.",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Settings"),
      ...waitForRoute("Taxes", "Terms"),
    },
    // 7
    {
      title: "Taxes",
      content:
        "These are the taxes applied to your products. Your add multiple tax rates and select the applicable ones when you setup each product.",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Taxes"),
      ...waitForRoute("CreateTax", "Settings"),
    },
    // 8
    {
      title: "Taxes",
      content: "Please create a tax rate",
      params: {
        placement: "top",
      },
      ...goToRouteBeforeStep("CreateTax"),
      ...waitForStoreAction("taxes/createTax"),
    },
    // 9
    {
      title: "Taxes",
      content:
        "That's it! You successfully created tax rate for your future products",
      params: {
        placement: "top",
      },
      ...goToRouteBeforeStep("Taxes"),
    },
    // 10
    {
      title: "Upsell",
      content: "To continue, go to the Upsell merchandise section.",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Settings"),
      ...waitForRoute("UpsellItems", "Taxes"),
    },
    // 11
    {
      title: "Upsell",
      content:
        "Upsell are additions that customers can make to their booking selection. Examples include: a trophy, a hat, a keychain, a water bottle... It's up to you",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("UpsellItems"),
      ...waitForRoute("CreateUpsellItem", "Settings"),
    },
    // 12
    {
      title: "Upsell",
      content: "Please create an upsell merchandise",
      params: {
        placement: "top",
      },
      ...goToRouteBeforeStep("CreateUpsellItem"),
      ...waitForStoreAction("upsellItems/createUpsellItem"),
    },
    // 13
    {
      title: "Upsell",
      content:
        "That's it! You successfully created upsell merchandise for your future products",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("UpsellItems"),
    },
    // 14
    {
      title: "Waivers",
      content: "Let’s navigate to the Waivers tab.",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Dashboard"),
      ...waitForRoute("Waivers", "UpsellItems"),
    },
    // 15
    {
      title: "Waivers",
      content:
        "This is your waivers of liability. YoReferee will help you collect digital signatures so you don't have to keep paper copies on hand.",
      params: {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Waivers"),
      ...waitForRoute("CreateWaiver", "Dashboard"),
    },
    // 16
    {
      title: "Waivers",
      content: "Please create a waiver",
      params: {
        highlight: false,
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      isArrowDisabled: true,
      ...goToRouteBeforeStep("CreateWaiver"),
      ...waitForStoreAction("waivers/createWaiver"),
    },
    // 17
    {
      title: "Waivers",
      content:
        "That's it! You successfully created waiver for your future products",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Waivers"),
    },
    // 18
    {
      title: "Lane management",
      content: "Let’s navigate to the Lane management tab.",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Dashboard"),
      ...waitForRoute("Resources", "Waivers"),
    },
    // 19
    {
      title: "Lane management",
      content:
        "These are the resources you have available to book products. It's up to you how you want to manage this. Example: i have a room with 4 targets that can fit up to 20 people. I'll name that \"front room\" and limit it at 20 capacity",
      params: {
        highlight: false,
        placement: "top",
      },
      isArrowDisabled: true,
      ...goToRouteBeforeStep("Resources"),
      ...waitForEvent("createResourceModalOpened"),
    },
    // 20
    {
      title: "Lane management",
      content: "Please create a lane",
      params: {
        placement: "top",
      },
      ...goToRouteBeforeStep("Resources", "resourceFormDialog"),
      ...waitForStoreAction("resources/createResource"),
    },
    // 21
    {
      title: "Lane management",
      content:
        "That's it! You successfully created a lane for your future products",
      params: {
        highlight: false,
        placement: "top",
      },
      isArrowDisabled: true,
      ...goToRouteBeforeStep("Resources"),
    },
    // 22
    {
      title: "Product",
      content: "Let’s navigate to the Products tab.",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Dashboard"),
      ...waitForRoute("Products", "Resources"),
    },
    // 23
    {
      title: "Product",
      content:
        'These are your products for sale, for example "One hour of Axe throwing"',
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Products"),
      ...waitForRoute("CreateProduct", "Dashboard"),
    },
    // 24
    {
      title: "Product",
      content:
        "Please create a product. Note: You can select all the different resources that this product can pull from, example: Front room. You can select multiple lanes if they all apply",
      params: {
        highlight: false,
        placement: "top-start",
      },
      isArrowDisabled: true,
      ...goToRouteBeforeStep("CreateProduct"),
      ...waitForStoreAction(
        "products/createProduct",
        store.state.products?.list.length > 0
      ),
    },
    // 25
    {
      title: "Product",
      content:
        "That's it! You successfully created a product for your future bookings",
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Products"),
    },
    // 26
    {
      title: "Calendar",
      content: "Let’s navigate to the Calendar tab.",
      params: {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 50],
            },
          },
        ],
      },
      ...goToRouteBeforeStep("Dashboard"),
      ...waitForRoute("Calendar", "Products"),
    },
    // 27
    {
      title: "Calendar",
      content:
        'This is how you will setup the times when your products are available for sale. Click "Create availability"',
      params: {
        placement: "bottom",
      },
      ...goToRouteBeforeStep("Calendar"),
      ...waitForEvent("createAvailabilityModalOpened"),
    },
    // 28
    {
      title: "Calendar",
      content:
        "Please create an availability. Select the product you would like to create timeslots for. Input your start time, duration, and any break time between sessions. When you click the plus icon YoReferee will automatically add a new session after the previous session has ended.",
      params: {
        placement: "right-start",
      },
      ...goToRouteBeforeStep("Calendar", "availabilitiesModalCreate"),
      ...waitForStoreAction("calendar/createAvailability"),
    },
    // 29
    {
      title: "Calendar",
      content:
        "That's it! You successfully created an availability for your future bookings",
      params: {
        highlight: false,
        placement: "top-start",
      },
      isArrowDisabled: true,
      ...goToRouteBeforeStep("Calendar"),
    },
    // 30
    {
      title: `Conclusion`,
      content:
        "That's it! You're ready to rock. Make sure you have verified your stripe account to process credit cards and you will be taking booking in no time! If you need any support or assistance please reach out to support@yoreferee.com",
      params: {
        highlight: false,
        enableScrolling: false,
      },
      isFixed: true,
      ...goToRouteBeforeStep("Dashboard"),
    },
  ].map((step) => {
    const modifiers = step.params.modifiers ? step.params.modifiers : [];

    return {
      ...step,
      params: {
        ...step.params,
        modifiers: [
          ...modifiers,
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              padding: 4,
            },
          },
        ],
      },
    };
  });
