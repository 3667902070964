<template>
  <div class="form-line" :style="{ padding: `${top}px 0 ${bottom}px` }" />
</template>

<script>
export default {
  name: "FormLine",
  props: {
    top: {
      type: Number,
      default: 32,
    },
    bottom: {
      type: Number,
      default: 32,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-line {
  height: 1px;
  width: 100%;
  background-color: $secondary-300;
}
</style>
