import {
  createDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { BookingStatusEnum } from "@/helpers/enums";
import { where } from "firebase/firestore";
import alert from "@/plugins/alert";
import moment from "moment";

export default {
  async fetchProducts({ commit, rootState, state }) {
    if (rootState.venues.selectedVenue?.id) {
      const products = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/products`,
        where("isDeleted", "==", false)
      );
      const newSelectedProduct =
        products.find((product) => product.id === state.selectedProduct?.id) ||
        products[0] ||
        null;
      commit("SET_SELECTED_PRODUCT", newSelectedProduct);
      commit("SET_LIST", products);
    } else {
      commit("SET_SELECTED_PRODUCT", null);
      commit("SET_LIST", []);
    }
  },
  async fetchProductById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const product = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/products`,
        id
      );
      commit("SET_EDITED_ITEM", product);
    }
  },
  async createProduct({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products`,
        data
      );
    }
  },
  async updateProduct({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products`,
        id,
        data
      );
    }
  },
  async deleteProduct({ rootState }, { id }) {
    if (rootState.venues.selectedVenue?.id) {
      const bookings = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        where("status", "in", [
          BookingStatusEnum.WAIT_FOR_PAYMENT,
          BookingStatusEnum.PAID,
        ]),
        where("date", ">", moment.utc().toDate()),
        where("productId", "==", id)
      );

      bookings?.forEach((booking) => {
        const totalPaid =
          booking.checkoutInfo?.payments.reduce((prev, item) => {
            return prev + (item.paid || 0) - (item.refunded || 0);
          }, 0) || 0;
        if (!(booking.isRefunded && totalPaid)) {
          const message = "Product with active bookings can't be deleted";
          alert.open({
            message,
            variant: "danger",
          });
          throw new Error(message);
        }
      });

      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products`,
        id,
        {
          isDeleted: true,
        }
      );
    }
  },
  async selectActiveWaiver({ dispatch, state, rootState, commit }, waiver) {
    if (rootState.venues.selectedVenue?.id && state.selectedProduct) {
      try {
        const newActiveWaiverId =
          state.selectedProduct.activeWaiverId !== waiver.id ? waiver.id : null;
        const newSelectedProduct = {
          ...state.selectedProduct,
          activeWaiverId: newActiveWaiverId,
        };
        const index = state.list.findIndex(
          (product) => product.id === newSelectedProduct.id
        );
        const newList = [...state.list];
        newList.splice(index, 1, newSelectedProduct);
        commit("SET_SELECTED_PRODUCT", newSelectedProduct);
        commit("SET_LIST", newList);
        await dispatch("updateProduct", {
          id: state.selectedProduct.id,
          data: {
            activeWaiverId: newActiveWaiverId,
          },
        });
      } catch (err) {
        await dispatch("fetchProducts");
        throw err;
      }
    }
  },
};
