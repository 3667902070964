import { getDate } from "@/helpers/utils";
import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { where } from "firebase/firestore";
import CalendarService from "@/api/services/CalendarService";
import moment from "moment";

export default {
  changeWeek({ commit, getters }, direction) {
    const week = getters.getCurrentWeek;
    const day =
      direction === "prev"
        ? getDate(week[0].date, false).add(-7, "d").format("YYYY-MM-DD")
        : getDate(week[week.length - 1].date, false)
            .add(1, "d")
            .format("YYYY-MM-DD");
    commit("SET_DATE", day);
  },
  changeMonth({ commit, state }, direction) {
    let month = moment(state.month);
    month = month.add(direction === "prev" ? -1 : 1, "month").format("YYYY-MM");

    commit("SET_MONTH", month);
  },
  async createAvailability({ rootState }, { productId, ...data }) {
    if (rootState.venues.selectedVenue && productId) {
      const doc = await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products/${productId}/slotGroups`,
        data
      );
      await CalendarService.populateSlotGroup({ productId, groupId: doc.id });
    }
  },
  async fetchCalendar({ rootState, commit, state }, { to, from, limit }) {
    if (rootState.venues.selectedVenue) {
      const productIds = state.productIds.join(",");
      const calendar = await CalendarService.getCalendar({
        productIds,
        to,
        from,
        limit,
      });
      commit("SET_CALENDAR", calendar);
    }
  },
  async updateSlot({ rootState }, { id, productId, ...data }) {
    if (
      rootState.venues.selectedVenue?.id &&
      (productId || rootState.products.selectedProduct?.id)
    ) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/products/${
          productId || rootState.products.selectedProduct?.id
        }/slots`,
        id,
        {
          ...data,
          slotTemplateId: null,
        }
      );
      return await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        where("date", ">", moment.utc().toDate()),
        where("slotIds", "array-contains", id)
      );
    }
  },
  async deleteSlot({ rootState }, { productId, id }) {
    const venueId = rootState.venues.selectedVenue.id;
    if (venueId) {
      await deleteDocument(
        `/venues/${venueId}/products/${productId}/slots`,
        id
      );
      return getCollection(
        `/venues/${venueId}/bookings`,
        where("date", ">", moment.utc().toDate()),
        where("slotIds", "array-contains", id)
      );
    }
  },
  async setNonWorkingDays({ rootState, commit }, { productId, days }) {
    const venueId = rootState.venues.selectedVenue?.id;
    if (venueId && productId) {
      await updateDocument(`/venues/${venueId}/products/`, productId, {
        nonWorkingDays: days,
      });

      commit(
        "products/SET_SELECTED_PRODUCT",
        {
          ...rootState.products.selectedProduct,
          nonWorkingDays: days,
        },
        { root: true }
      );
      const products = [...rootState.products.list];
      const productIndex = rootState.products.list.findIndex(
        (product) => product.id === productId
      );
      products[productIndex] = {
        ...products[productIndex],
        nonWorkingDays: days,
      };
      commit("products/SET_LIST", products, { root: true });
    }
  },
  async fetchGroupById({ rootState, commit }, { id, productId, isClone }) {
    if (rootState.venues.selectedVenue?.id && productId && id) {
      const group = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/products/${productId}/slotGroups`,
        id
      );
      if (group) {
        if (isClone) {
          delete group.id;
        }
        commit("SET_EDITING_GROUP", { ...group, productId });
      }
    }
  },
  async fetchGroups({ rootState, commit, state }) {
    if (rootState.venues.selectedVenue?.id) {
      const groups = await Promise.all(
        state.productIds.map((productId) => {
          const product = rootState.products.list.find(
            (product) => product.id === productId
          );
          return getCollection(
            `/venues/${rootState.venues.selectedVenue.id}/products/${productId}/slotGroups`
          ).then((groups) =>
            groups.map((group) => ({
              ...group,
              productId: product?.id,
              productName: product?.name,
              productIcon: product?.icon,
            }))
          );
        })
      ).then((groupsArrays) =>
        groupsArrays
          .flat()
          .sort((a, b) => moment(b.startDate).diff(a.startDate))
      );

      commit("SET_GROUPS", groups);
    }
  },
};
