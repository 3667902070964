import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { SvgIconsCollection, install, Packages } from "@zteam/booking-axe-sb";
import { auth } from "@/plugins/firebase";
import VModal from "vue-js-modal";
import VueSignaturePad from "vue-signature-pad";
import "./plugins/firebase";
import InfiniteLoading from "vue-infinite-loading";
import "@/assets/styles/main.scss";
import "@zteam/booking-axe-sb/assets/styles/main.scss";
import VueI18n from "vue-i18n";
import en from "@/assets/locales/en.json";
import { handleOnboard } from "@/helpers/onboard";
import VueMq from "vue-mq";
import VTooltip from "v-tooltip";
import device from "vue-device-detector";
import browserDetect from "vue-browser-detect-plugin";
import VueTour from "vue-tour";
import VueGoodTablePlugin from "vue-good-table";
import iframeResize from "iframe-resizer/js/iframeResizer";
import "vue-good-table/dist/vue-good-table.css";
import "vue-tour/dist/vue-tour.css";
import * as path from "path";

Vue.use(VueMq, {
  breakpoints: {
    xs: 768,
    sm: 1024,
    md: 1440,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(InfiniteLoading);
Vue.use(VModal);
Vue.use(VueSignaturePad);
Vue.use(install);
Vue.use(Packages);
Vue.use(VTooltip);
Vue.use(browserDetect);
Vue.use(device);
Vue.use(VueTour);
Vue.use(VueGoodTablePlugin);

export let app = null;
const i18n = new VueI18n({
  locale: "en-US",
  messages: {
    en,
  },
});

Vue.directive("resize", {
  bind: function (el, { value }) {
    if (value?.active) {
      el.addEventListener("load", () => iframeResize(value.params || {}, el));
    }
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  },
});

const requireSvgIcon = require.context("@/assets/icons", false, /\.svg$/);
const svgIconsCollection = requireSvgIcon.keys().reduce((prev, filePath) => {
  const iconName = path.basename(filePath, ".svg");
  return {
    ...prev,
    [iconName]: requireSvgIcon(filePath),
  };
}, {});
auth.onAuthStateChanged(async (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
      provide: {
        [SvgIconsCollection]: svgIconsCollection,
      },
    }).$mount("#app");
  }
  if (user) {
    try {
      store.commit("SET_IS_GLOBAL_LOADING", true);
      const queryVenue = new URLSearchParams(window.location.search).get(
        "venue"
      );
      store.commit("venues/SET_QUERY_VENUE", queryVenue);
      await store.dispatch("users/fetchIsSuperadmin");
      await store.dispatch("venues/fetchVenue", queryVenue);
      await Promise.all([
        store.dispatch("users/fetchVenueUserJunction"),
        store.dispatch("users/fetchOnboardingProgress"),
        store.dispatch("products/fetchProducts"),
        handleOnboard(router.currentRoute, (route) => router.push(route)),
      ]);
    } finally {
      store.commit("SET_IS_GLOBAL_LOADING", false);
    }
  } else {
    store.commit("SET_IS_GLOBAL_LOADING", false);
  }
});
