import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

let abortController;

export default class UsersService extends ServiceBase {
  static getUsers(params) {
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    const url = "/private/users";

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: abortController.signal,
    });
  }

  static getUserById(id) {
    const url = `/private/users/${id}`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
    });
  }

  static addUser(data, venueId = null) {
    const url = "/private/users";

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
      venueId,
    });
  }

  static deleteUser(id) {
    const url = `/private/users/${id}`;

    return this.callApi({
      method: "DELETE",
      url,
      baseURL,
    });
  }

  static changeUserRole(id, role) {
    const url = `/private/users/${id}/role`;

    return this.callApi({
      method: "PATCH",
      url,
      baseURL,
      data: { role },
    });
  }

  static updateUser(id, data) {
    const url = `/private/users/${id}`;

    return this.callApi({
      method: "PATCH",
      url,
      baseURL,
      data,
    });
  }
}
