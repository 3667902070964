import Vue from "vue";
import Vuex from "vuex";
import products from "./products";
import venues from "./venues";
import bookings from "./bookings";
import taxes from "./taxes";
import promocodes from "./promocodes";
import affiliates from "./affiliates";
import waivers from "./waivers";
import bankAccounts from "./bankAccounts";
import signatures from "./signatures";
import resources from "./resources";
import calendar from "./calendar";
import users from "./users";
import terms from "./terms";
import upsellItems from "./upsellItems";
import mailingTemplates from "./mailingTemplates";
import widgetTemplates from "./widgetTemplates";
import dashboard from "./dashboard";
import superadmin from "./superadmin";
import integrations from "./integrations";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const persistedState = createPersistedState({
  paths: [
    "isSidebarCollapsed",
    "venues.selectedVenue.id",
    "products.selectedProduct.id",
    "bookings.selectedView",
    "bookings.activeSortField",
    "calendar.view",
    "dashboard.statistics",
    "dashboard.lastFetchedAt",
    "dashboard.periodFilter",
    "dashboard.dateRangeFilter",
    "dashboard.dateField",
    "calendar.productIds",
    "calendar.isDrawerOpen",
  ],
});

const state = {
  isGlobalLoading: true,
  isSidebarCollapsed: false,
};
const mutations = {
  SET_IS_GLOBAL_LOADING(state, isGlobalLoading) {
    state.isGlobalLoading = isGlobalLoading;
  },
  SET_IS_SIDEBAR_COLLAPSED(state, isCollapsed) {
    state.isSidebarCollapsed = isCollapsed;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    products,
    venues,
    bookings,
    taxes,
    promocodes,
    affiliates,
    waivers,
    bankAccounts,
    signatures,
    resources,
    calendar,
    users,
    terms,
    upsellItems,
    mailingTemplates,
    dashboard,
    superadmin,
    integrations,
    widgetTemplates,
  },
  plugins: [persistedState],
});
