<template>
  <Alert
    :is-show="alert.state.active"
    :message="alert.state.message"
    :variant="alert.state.variant"
    :is-closable="false"
  />
</template>

<script>
import alert from "@/plugins/alert";

export default {
  name: "GlobalAlert",
  data() {
    return { alert };
  },
};
</script>
