import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class IntegrationsService extends ServiceBase {
  static getAllIntegrations(venueId = null) {
    const url = "/private/integrations";

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      venueId,
    });
  }

  static addIntegration(data, venueId = null) {
    const url = "/private/integrations";

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
      venueId,
    });
  }
}
