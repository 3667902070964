import {
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { doc, runTransaction, where } from "firebase/firestore";
import { db } from "@/plugins/firebase";
import dialog from "@/plugins/dialog";
import { BookingStatusEnum } from "@/helpers/enums";
import moment from "moment/moment";

export const getResourceById = (id, venueId) => {
  return getDocById(`/venues/${venueId}/resources`, id);
};

export default {
  async fetchResources({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const resources = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/resources`
      );
      commit("SET_LIST", resources);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchResourceById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const resource = await getResourceById(
        id,
        rootState.venues.selectedVenue.id
      );
      commit("SET_EDITED_ITEM", resource);
    }
  },
  async updateResource({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/resources`,
        id,
        data
      );
    }
  },
  async createResource({ rootState }, { id, ...data }) {
    if (rootState.venues.selectedVenue?.id) {
      await runTransaction(db, async (transaction) => {
        let exists;
        const collectionPath = `/venues/${rootState.venues.selectedVenue.id}/resources`;
        const resourceSnap = await transaction.get(doc(db, collectionPath, id));
        exists = resourceSnap.exists();

        if (exists) {
          throw `Lane with ID '${id}' already exists`;
        }

        transaction.set(doc(db, collectionPath, id), data);
      });
    }
  },
  async deleteResource({ rootState }, id) {
    const venueId = rootState.venues.selectedVenue?.id;
    if (venueId) {
      const affectedBookings = await getCollection(
        `/venues/${venueId}/bookings`,
        where("date", ">", moment.utc().toDate()),
        where("resourceIds", "array-contains", id),
        where("status", "in", [
          BookingStatusEnum.NEW,
          BookingStatusEnum.PAID,
          BookingStatusEnum.WAIT_FOR_PAYMENT,
        ])
      );
      if (affectedBookings.length) {
        setTimeout(async () => {
          await dialog.alert({
            title: "Warning",
            message: "The lane with upcoming bookings cannot be deleted",
            okText: "Done",
          });
        }, 300);
        return;
      }
      await deleteDocument(`/venues/${venueId}/resources`, id);
    }
  },
  async setClosurePercent({ rootState, commit }, closurePercent) {
    if (rootState.venues.selectedVenue?.id) {
      try {
        await updateDocument(`/venues/`, rootState.venues.selectedVenue?.id, {
          closurePercent,
        });
        commit(
          "venues/SET_SELECTED_VENUE",
          {
            ...rootState.venues.selectedVenue,
            closurePercent,
          },
          { root: true }
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
};
