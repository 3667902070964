import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";

export default {
  async fetchTaxes({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const taxes = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/taxes`
      );
      commit("SET_LIST", taxes);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchTaxById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const tax = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/taxes`,
        id
      );
      commit("SET_EDITED_ITEM", tax);
    }
  },
  async updateTax({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/taxes`,
        id,
        data
      );
    }
  },
  async createTax({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/taxes`,
        data
      );
    }
  },
  async deleteTax({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/taxes`,
        id
      );
    }
  },
};
