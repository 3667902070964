import {
  RoleEnum,
  StandardWaiverFieldsEnum,
  CustomFieldTypeEnum,
  RangeButtonsEnum,
  PromoRateTypeEnum,
  TaxBehaviourEnum,
  WidgetViewColorEnum,
  WidgetPositionEnum,
} from "@/helpers/enums";
import {
  DEFAULT_PAYMENT_METHODS,
  ROLE_NAMES_MAP,
  STANDARD_WAIVER_FIELDS_LABELS_MAP,
  STANDARD_WAIVER_FIELDS_TYPES_MAP,
} from "@/helpers/const";

export const CALENDAR_VIEW_TYPE_ENUM = {
  WEEK: "week",
  MONTH: "month",
};

export const PRODUCT_MENU_OPTION_VALUES = {
  COPY: "copy",
  PREVIEW: "preview",
  DELETE: "delete",
  EDIT: "edit",
  ACTIVATE: "activate",
  PAUSE: "pause",
};

export const CALENDAR_CELL_MENU_OPTION_VALUES = {
  CREATE_BOOKING: "createBooking",
  VIEW_BOOKINGS: "viewBookings",
  EDIT_AVAILABILITY: "editAvailability",
  CLONE_AVAILABILITY: "cloneAvailability",
};

export const BOOKING_MENU_OPTION_VALUES = {
  OPEN: "open",
  FAVORITE: "favorite",
  SIGN: "sign",
};

export const DAYS_MOCK_MONTH = [
  {
    date: "2022-05-08T00:00:00.000Z",
  },
  {
    date: "2022-05-09T00:00:00.000Z",
  },
  {
    date: "2022-05-10T00:00:00.000Z",
  },
  {
    date: "2022-05-11T00:00:00.000Z",
  },
  {
    date: "2022-05-12T00:00:00.000Z",
  },
  {
    date: "2022-05-13T00:00:00.000Z",
  },
  {
    date: "2022-05-14T00:00:00.000Z",
  },
];

export const SIDEBAR_MENU_ITEMS = [
  {
    icon: "statistic",
    label: "Dashboard",
    route: { name: "Dashboard" },
  },
  {
    icon: "rectangle",
    label: "Bookings",
    route: { name: "Bookings" },
  },
  {
    icon: "calendar",
    label: "Calendar",
    route: { name: "Calendar" },
    attributes: {
      "data-v-step": 26,
    },
  },
  {
    icon: "clock",
    label: "Lane management",
    route: { name: "Resources" },
    attributes: {
      "data-v-step": 18,
    },
  },
  {
    icon: "briefcase",
    label: "Products",
    route: { name: "Products" },
    attributes: {
      "data-v-step": 22,
    },
  },
  {
    icon: "laptop-pencil",
    label: "Waivers",
    route: { name: "Waivers" },
    attributes: {
      "data-v-step": 14,
    },
  },
  {
    icon: "users",
    label: "Users",
    route: { name: "Users" },
  },
  {
    icon: "settings",
    label: "Settings",
    route: { name: "Settings" },
    attributes: {
      "data-v-step": 1,
    },
  },
];

export const SETTINGS_MENU_ITEMS = [
  {
    label: "Profile",
    route: { name: "Profile" },
  },
  {
    label: "Venue",
    route: { name: "EditVenue" },
  },
  {
    label: "Widget templates",
    route: { name: "WidgetTemplates" },
  },
  {
    label: "Bank accounts",
    route: { name: "BankAccounts" },
    permission: "bankAccounts.view",
  },
  {
    label: "Taxes",
    route: { name: "Taxes" },
    attributes: {
      "data-v-step": 6,
    },
  },
  {
    label: "Mailing templates",
    route: { name: "MailingTemplates" },
  },
  {
    label: "Terms & Conditions",
    route: { name: "Terms" },
    attributes: {
      "data-v-step": 2,
    },
  },
  {
    label: "Promocodes",
    route: { name: "Promocodes" },
  },
  {
    label: "Affiliates",
    route: { name: "Affiliates" },
  },
  {
    label: "Upsell merchandise",
    route: { name: "UpsellItems" },
    attributes: {
      "data-v-step": 10,
    },
  },
  {
    label: "Permissions",
    route: { name: "Permissions" },
  },
  {
    label: "Superadmin",
    route: { name: "Superadmin" },
    isSuperadmin: true,
  },
  {
    label: "Integrations",
    route: { name: "Integrations" },
  },
];

export const WORKING_HOURS_MOCK = {
  dates: {},
  weekdays: {
    0: {
      from: 10,
      to: 18,
      isWorking: false,
    },
    1: {
      from: 10,
      to: 18,
      isWorking: true,
    },
    2: {
      from: 10,
      to: 18,
      isWorking: true,
    },
    3: {
      from: 10,
      to: 18,
      isWorking: true,
    },
    4: {
      from: 10,
      to: 18,
      isWorking: true,
    },
    5: {
      from: 10,
      to: 18,
      isWorking: true,
    },
    6: {
      from: 10,
      to: 18,
      isWorking: false,
    },
  },
};

export const NEW_PRODUCT_MOCK = {
  name: "",
  title: "",
  description: "",
  cutoffTime: "",
  duration: "",
  image: "",
  breakTime: "",
  price: "",
  deposit: "",
  maxPlayers: "",
  minPlayers: "",
  fixedPrice: "",
  resources: [],
  taxes: [],
  nonWorkingDays: [],
  holidays: [],
  isPaused: true,
  isDeleted: false,
  isDeposit: false,
  customFields: {},
  termsId: "",
  largeGroupDisclaimerId: "",
  upsellItemIds: [],
  cancellationPeriodAmount: "",
  cancellationPeriodUnit: "",
  widgetNote: "",
  typeOfPricing: "",
  groups: [],
};

export const NEW_VENUE_MOCK = {
  id: "",
  name: "",
  companyName: "",
  closurePercent: "",
  companySite: "",
  country: "",
  timezone: "",
  address: "",
  phone: "",
  email: "",
  ownerEmail: "",
  registrationCodePrefix: "",
  firstDayOfWeek: 0,
  paymentMethods: [...DEFAULT_PAYMENT_METHODS],
  phoneFormat: "+X (XXX) XXX-XX-XX",
  moneyFormat: "${{amount}}",
};

export const NEW_BOOKING_MOCK = {
  reservationInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    note: "",
  },
  date: "",
  slots: [],
  playersCount: "",
  typeOfPricing: "",
  selectedGroupId: "",
  isWalkIn: false,
  isSpecifyGroupSize: false,
  isFullFirstPayment: false,
  isConfirmationEmail: true,
  affiliateId: "",
};

export const NEW_TAX_MOCK = {
  name: "",
  rate: "",
  description: "",
};

export const NEW_PROMOCODE_MOCK = {
  code: "",
  maxUseNumber: 1,
  isActive: true,
  discount: 0,
  discountType: PromoRateTypeEnum.PERCENT,
  taxBehaviour: TaxBehaviourEnum.BEFORE,
  products: [],
  upsellItems: [],
};

export const NEW_AFFILIATE_MOCK = {
  name: "",
  businessName: "",
  isActive: true,
  discount: 0,
  discountType: PromoRateTypeEnum.PERCENT,
  products: [],
  upsellItems: [],
  address: {
    country: "",
    state: "",
    city: "",
    zip: "",
    street: "",
  },
};

export const getNewWaiverFieldMock = ({
  type,
  label,
  sortWeight,
  isRequired,
}) => {
  const field = {
    type,
    label,
    hint: "",
    isRequired,
    sortWeight,
    isDisabled: false,
    isAllowCustomOption: false,
  };
  if (
    [CustomFieldTypeEnum.MULTIPLE, CustomFieldTypeEnum.SELECT].includes(type)
  ) {
    field.options = ["Option 1", "Option 2"];
  }
  return field;
};

const getWaiverFieldsMock = () => {
  const fields = {};
  Object.values(StandardWaiverFieldsEnum).forEach((key, index) => {
    fields[key] = getNewWaiverFieldMock({
      type: STANDARD_WAIVER_FIELDS_TYPES_MAP[key],
      label: STANDARD_WAIVER_FIELDS_LABELS_MAP[key],
      sortWeight: index * 10,
      isRequired: true,
    });
  });
  return fields;
};

export const NEW_WAIVER_MOCK = {
  title: "",
  intro: "",
  text: "",
  fields: getWaiverFieldsMock(),
  checkboxes: [],
  startTimeHours: "",
  cutOffHours: "",
};

export const NEW_BANK_ACCOUNT_MOCK = {
  accountHolderName: "",
  country: "",
  accountNumber: "",
  routingNumber: "",
};

export const NEW_TERMS_MOCK = {
  name: "",
  description: "",
  text: "",
};

export const NEW_UPSELL_ITEM_MOCK = {
  name: "",
  description: "",
  image: "",
  price: "",
};

export const NEW_MAILING_TEMPLATE_MOCK = {
  event: "",
  subject: "",
  to: [],
  cc: [],
  bcc: [],
  replyTo: [],
  text: "",
  isPaused: true,
};

export const CUSTOM_FIELD_TYPE_OPTIONS = [
  {
    value: CustomFieldTypeEnum.TEXT,
    name: "Text",
  },
  {
    value: CustomFieldTypeEnum.PHONE,
    name: "Phone",
  },
  {
    value: CustomFieldTypeEnum.EMAIL,
    name: "Email",
  },
  {
    value: CustomFieldTypeEnum.DATE,
    name: "Date",
  },
  {
    value: CustomFieldTypeEnum.NUMERIC,
    name: "Numeric",
  },
  {
    value: CustomFieldTypeEnum.SELECT,
    name: "Select",
  },
  {
    value: CustomFieldTypeEnum.MULTIPLE,
    name: "Multiple selection",
  },
  {
    value: CustomFieldTypeEnum.FLAG,
    name: "True/False",
  },
];

export const CUSTOM_FIELD_TYPE_ICON_MAP = {
  [CustomFieldTypeEnum.TEXT]: "letter-a",
  [CustomFieldTypeEnum.PHONE]: "phone",
  [CustomFieldTypeEnum.EMAIL]: "email",
  [CustomFieldTypeEnum.SELECT]: "list",
  [CustomFieldTypeEnum.DATE]: "calendar",
  [CustomFieldTypeEnum.NUMERIC]: "number-1",
  [CustomFieldTypeEnum.MULTIPLE]: "checkboxes",
  [CustomFieldTypeEnum.FLAG]: "checkmark-circle",
};

export const NEW_RESOURCE_MOCK = {
  id: "",
  capacity: "",
  minCapacity: "",
  type: "Lane",
  isPaused: false,
};

export const roleOptions = [
  {
    value: RoleEnum.OWNER,
    name: ROLE_NAMES_MAP[RoleEnum.OWNER],
  },
  {
    value: RoleEnum.MANAGER,
    name: ROLE_NAMES_MAP[RoleEnum.MANAGER],
  },
  {
    value: RoleEnum.STAFF,
    name: ROLE_NAMES_MAP[RoleEnum.STAFF],
  },
];

export const dateRangeOptions = [
  {
    name: "Yesterday",
    value: RangeButtonsEnum.YESTERDAY,
  },
  {
    name: "Today",
    value: RangeButtonsEnum.TODAY,
  },
  {
    name: "Tomorrow",
    value: RangeButtonsEnum.TOMORROW,
  },
  {
    name: "This week",
    value: RangeButtonsEnum.THIS_WEEK,
  },
  {
    name: "Last week",
    value: RangeButtonsEnum.WEEK,
  },
  {
    name: "This month",
    value: RangeButtonsEnum.THIS_MONTH,
  },
  {
    name: "Last month",
    value: RangeButtonsEnum.MONTH,
  },
  {
    name: "This year",
    value: RangeButtonsEnum.THIS_YEAR,
  },
  {
    name: "Last year",
    value: RangeButtonsEnum.YEAR,
  },
  {
    name: "Custom",
    value: RangeButtonsEnum.CUSTOM,
  },
];

export const DEFAULT_BOOKINGS_TABLE_COLUMNS = [
  {
    id: "presenceStatus",
    label: "Status",
    isHidden: false,
  },
  {
    id: "createdAt",
    label: "Date Created",
    isHidden: false,
  },
  {
    id: "date",
    label: "Date of Event",
    isHidden: false,
  },
  {
    id: "customerFullName",
    label: "Name",
    isHidden: false,
  },
  { id: "customerEmail", label: "Email", isHidden: false },
  {
    id: "customerPhone",
    label: "Phone",
    isHidden: false,
  },
  {
    id: "lanes",
    label: "Lanes",
    isHidden: false,
  },
  {
    id: "duration",
    label: "Duration",
    isHidden: false,
  },
  {
    id: "regCode",
    label: "Reg.Code",
    isHidden: false,
  },
  {
    id: "waivers",
    label: "Waivers",
    isHidden: false,
  },
  {
    id: "paid",
    label: "Paid",
    isHidden: false,
  },
  {
    id: "paymentDue",
    label: "Payment Due",
    isHidden: false,
  },
];

export const WIDGET_VIEW_FONT_FAMILIES = [
  "Helvetica Neue",
  "Euclid Circular B",
  "Arial",
  "Verdana",
  "Tahoma",
  "Georgia",
  "Times New Roman",
];
export const DEFAULT_WIDGET_VIEW = {
  fontFamily: WIDGET_VIEW_FONT_FAMILIES[0],
  colors: {
    [WidgetViewColorEnum.PRIMARY]: "#286eed",
    [WidgetViewColorEnum.SECONDARY]: "#262626",
    [WidgetViewColorEnum.AVAILABILITY_LOW]: "#ff7171",
    [WidgetViewColorEnum.AVAILABILITY_MODERATE]: "#d59d00",
    [WidgetViewColorEnum.AVAILABILITY_HIGH]: "#286eed",
  },
  isUseAvailabilityColors: true,
};

export const WIDGET_VIEW_COLOR_PRESETS = [
  {
    name: "A",
    colors: DEFAULT_WIDGET_VIEW.colors,
  },
  {
    name: "B",
    colors: {
      [WidgetViewColorEnum.PRIMARY]: "#ED6A3F",
      [WidgetViewColorEnum.SECONDARY]: "#262626",
      [WidgetViewColorEnum.AVAILABILITY_LOW]: "#ff7171",
      [WidgetViewColorEnum.AVAILABILITY_MODERATE]: "#d59d00",
      [WidgetViewColorEnum.AVAILABILITY_HIGH]: "#ED6A3F",
    },
  },
  {
    name: "C",
    colors: {
      [WidgetViewColorEnum.PRIMARY]: "#4F49BB",
      [WidgetViewColorEnum.SECONDARY]: "#262626",
      [WidgetViewColorEnum.AVAILABILITY_LOW]: "#ff7171",
      [WidgetViewColorEnum.AVAILABILITY_MODERATE]: "#d59d00",
      [WidgetViewColorEnum.AVAILABILITY_HIGH]: "#4F49BB",
    },
  },
];

export const NEW_WIDGET_TEMPLATE_MOCK = {
  name: "",
  productId: null,
  position: WidgetPositionEnum.FLOATING,
  isHideBeacon: false,
  isPromocodesAvailable: false,
  isActive: false,
  view: DEFAULT_WIDGET_VIEW,
  isDefault: false,
};
