import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";

export default {
  async fetchWaivers({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const waivers = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/waivers`
      );
      commit("SET_LIST", waivers);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchWaiverById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const waiver = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/waivers`,
        id
      );
      commit("SET_EDITED_ITEM", waiver);
    }
  },
  async updateWaiver({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/waivers`,
        id,
        data
      );
    }
  },
  async createWaiver({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/waivers`,
        data
      );
    }
  },
  async deleteWaiver({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/waivers`,
        id
      );
    }
  },
};
