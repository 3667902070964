import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";

export default {
  async fetchPromocodes({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const promocodes = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/promocodes`
      );
      commit("SET_LIST", promocodes);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchPromocodeById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const promocode = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/promocodes`,
        id
      );
      commit("SET_EDITED_ITEM", promocode);
    }
  },
  async updatePromocode({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/promocodes`,
        id,
        data
      );
    }
  },
  async createPromocode({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/promocodes`,
        data
      );
    }
  },
  async deletePromocode({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/promocodes`,
        id
      );
    }
  },
};
