<template>
  <ContextMenu
    :options="options"
    @select="!isDisabled && $emit('select', $event)"
  >
    <div class="dropdown" :class="{ 'm-disabled': isDisabled }">
      {{ selectedName }}
      <Icon name="chevron-down" color="secondary-500" />
    </div>
  </ContextMenu>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedName() {
      return (
        this.options.find((option) => option.value === this.value)?.name || "-"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: $secondary-500;
  cursor: pointer;

  &.m-disabled {
    pointer-events: none;
  }
}
</style>
