import actions from "./actions";
import mutations from "./mutations";

const state = {
  list: [],
  editedItem: null,
  bookings: [],
  lastBookingDoc: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
