<template>
  <div class="default-layout">
    <Header class="header" @burger-click="toggleSidebar" />
    <div class="sidebar-with-content">
      <Sidebar :active.sync="isSidebarActive" />
      <div class="space-container">
        <div
          class="content"
          :class="{
            'm-iphone': $device.iphone,
            'm-iphoneX':
              $device.iphoneX || $device.iPhoneXR || $device.iPhoneXSMax,
            'm-ipad': $device.ipad,
            'm-safari': isSafari && $browserDetect.meta.version === '15',
          }"
        >
          <slot />
        </div>
      </div>
    </div>
    <Alert
      :isShow="isShowStripeAlert"
      message="In order to receive payments for bookings please verify your Stripe account"
      variant="secondary"
      @close="closeStripeAlert"
    >
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        :isLoading="isLoading"
        isSmall
        @click="redirectToStripeOnboarding"
      >
        Verify
      </Button>
    </Alert>
  </div>
</template>

<script>
import Header from "@/components/common/Header";
import Sidebar from "@/components/common/Sidebar";
import StripeService from "@/api/services/StripeService";
import { mapState } from "vuex";

export default {
  name: "DefaultLayout",
  components: { Sidebar, Header },
  data() {
    return {
      isSidebarActive: false,
      isLoading: false,
      isClosedStripeAlert: false,
      isSafari: window?.navigator?.userAgent?.toLowerCase()?.includes("safari"),
    };
  },
  computed: {
    ...mapState({
      selectedVenue: (state) => state.venues.selectedVenue,
    }),
    isShowStripeAlert() {
      return (
        !this.selectedVenue?.isStripePaymentsEnabled &&
        !this.isClosedStripeAlert
      );
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    async redirectToStripeOnboarding() {
      try {
        this.isLoading = true;
        const onboardLink = await StripeService.getOnboardLink();
        window.location.replace(onboardLink.url);
      } catch {
        this.isLoading = false;
      }
    },
    closeStripeAlert() {
      this.isClosedStripeAlert = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
  }

  .sidebar-with-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: auto;

    .space-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-x: hidden;

      .content {
        flex: 1;
        padding: 40px 24px 48px;

        &.m-iphone {
          padding-bottom: 98px;

          &.m-iphoneX {
            padding-bottom: 148px;
          }

          &.m-safari {
            padding-bottom: 178px;
          }
        }

        &.m-ipad {
          padding-bottom: 148px;
        }
      }
    }

    @media (min-width: $media-laptop) {
      flex-direction: row;

      .space-container {
        .content {
          padding: 64px 44px 48px;
        }
      }
    }
  }
}
</style>
