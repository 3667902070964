import store from "@/store";

export const superadminMiddleware = (to, from, next) => {
  const resolve = () => {
    if (to.meta.isSuperadmin && !store.state.users.isSuperadmin) {
      return from ? next(from) : next("/");
    }
    return next();
  };
  if (store.state.users.isSuperadmin === null) {
    store.watch((state) => state.users.isSuperadmin, resolve);
    return next();
  } else {
    return resolve();
  }
};
