import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

let priceAbortController;
let slotsAbortController;
let datesAbortController;
let detailedAbortController;

export default class BookingService extends ServiceBase {
  static getFreeSlots({ productId, date, playersCount, bookingId }) {
    if (slotsAbortController) {
      slotsAbortController.abort();
    }
    slotsAbortController = new AbortController();

    const url = `/public/product/${productId}/slots`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params: {
        date,
        playersCount,
        bookingId,
      },
      signal: slotsAbortController.signal,
    });
  }

  static createBooking(data) {
    const url = `/public/booking`;

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
    });
  }

  static addReservationInfo({ bookingId, data }) {
    const url = `/public/booking/${bookingId}/reservationInfo`;

    return this.callApi({
      method: "PATCH",
      url,
      baseURL,
      data,
    });
  }

  static checkout({ bookingId, data }) {
    const url = `/private/booking/${bookingId}/checkout`;
    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
    });
  }

  static getPrice({ productId, params }) {
    if (priceAbortController) {
      priceAbortController.abort();
    }
    priceAbortController = new AbortController();

    const url = `/public/product/${productId}/price`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: priceAbortController.signal,
    });
  }

  static getDates({ productId, month, playersCount, bookingId }) {
    if (datesAbortController) {
      datesAbortController.abort();
    }
    datesAbortController = new AbortController();

    const url = `/public/product/${productId}/dates`;

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params: {
        bookingId,
        playersCount,
        month,
      },
      signal: datesAbortController.signal,
    });
  }

  static getDetailedBookings({
    activeFilter,
    dateRangeFilter,
    productId,
    searchField,
    searchQuery,
  }) {
    if (detailedAbortController) {
      detailedAbortController.abort();
    }
    detailedAbortController = new AbortController();

    const url = `/private/booking/detailed`;
    const params = {};
    if (searchField && searchQuery)
      params.search = `${searchField}:${searchQuery}`;
    if (productId) params.productId = productId;
    if (activeFilter) params.activeFilter = activeFilter;
    if (dateRangeFilter[0])
      if (params.dateRangeFilter)
        params.dateRangeFilter[0] = dateRangeFilter[0];
      else params.dateRangeFilter = [dateRangeFilter[0]];
    if (dateRangeFilter[1])
      if (params.dateRangeFilter)
        params.dateRangeFilter[1] = dateRangeFilter[1];
      else params.dateRangeFilter = [undefined, dateRangeFilter[1]];

    return this.callApi({
      method: "GET",
      url,
      baseURL,
      params,
      signal: detailedAbortController.signal,
    });
  }

  static refundBooking({ bookingId, amount }) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: `/private/booking/${bookingId}/refund`,
      data: {
        amount,
      },
    });
  }

  static sendEmail({ bookingId, email, type, subject, message, templateId }) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: `/private/booking/${bookingId}/sendEmail`,
      data: {
        email,
        type,
        subject,
        message,
        templateId,
      },
    });
  }

  static editBooking({ bookingId, ...data }) {
    return this.callApi({
      method: "PUT",
      baseURL,
      url: `/private/booking/${bookingId}`,
      data,
    });
  }

  static uploadMedia(data) {
    const url = "/public/media/images/upload";
    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
    });
  }

  static getBookingPaymentMethods(bookingId) {
    const url = `/private/booking/${bookingId}/payment-methods`;
    return this.callApi({
      method: "GET",
      url,
      baseURL,
    });
  }
}
