import actions from "./actions";
import mutations from "./mutations";
import moment from "moment";
import { RangeButtonsEnum } from "@/helpers/enums";

const nowString = moment().format("YYYY-MM-DD");
const state = {
  statistics: {
    [RangeButtonsEnum.TODAY]: null,
    [RangeButtonsEnum.THIS_WEEK]: null,
    [RangeButtonsEnum.WEEK]: null,
    [RangeButtonsEnum.THIS_MONTH]: null,
    [RangeButtonsEnum.MONTH]: null,
    [RangeButtonsEnum.THIS_YEAR]: null,
    [RangeButtonsEnum.YEAR]: null,
  },
  lastFetchedAt: {
    [RangeButtonsEnum.TODAY]: null,
    [RangeButtonsEnum.THIS_WEEK]: null,
    [RangeButtonsEnum.WEEK]: null,
    [RangeButtonsEnum.THIS_MONTH]: null,
    [RangeButtonsEnum.MONTH]: null,
    [RangeButtonsEnum.THIS_YEAR]: null,
    [RangeButtonsEnum.YEAR]: null,
  },
  detailsList: [],
  detailsChart: null,
  productFilterId: null,
  dateRangeFilter: [nowString, nowString],
  periodFilter: RangeButtonsEnum.TODAY,
  stripeBalance: null,
  payouts: [],
  lastPayoutId: null,
  dateField: "createdAt",
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
