import {
  getDocById,
  deleteDocument,
  updateDocument,
} from "@/helpers/firebase-helpers";
import IntegrationsService from "@/api/services/IntegrationsService";

export default {
  async fetchIntegrationsList({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const integrations = await IntegrationsService.getAllIntegrations(
        rootState.venues.selectedVenue.id,
      );
      commit("SET_LIST", integrations);
    } else {
      commit("SET_LIST", []);
    }
  },

  async fetchIntegrationById({ commit, rootState }, id) {
    const integration = await getDocById(
      `/venues/${rootState.venues.selectedVenue.id}/integrations`,
      id,
    );

    commit("SET_EDITED_ITEM", integration);
  },

  async createIntegration({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await IntegrationsService.addIntegration(
        data,
        rootState.venues.selectedVenue.id,
      );
    }
  },

  async deleteIntegration({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/integrations`,
        id,
      );
    }
  },

  async updateIntegration({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/integrations`,
        id,
        data,
      );
    }
  },
};
