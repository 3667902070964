<template>
  <transition name="fade" mode="out-in">
    <div v-if="isGlobalLoading" class="global-loader">
      <Loader color="primary" size="l" />
    </div>
    <component v-else :is="layout" v-bind="layoutProps" data-v-step="0">
      <div data-v-step="30" />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <GlobalAlert />
      <GlobalDialog />
      <PasswordDialog />
      <OnboardingGuide />
    </component>
  </transition>
</template>

<script>
import * as layouts from "@/layouts";
import FormLine from "@/components/common/FormLine";
import { mapActions, mapState } from "vuex";
import GlobalDialog from "@/components/common/GlobalDialog";
import GlobalAlert from "@/components/common/GlobalAlert";
import { getDate } from "./helpers/utils";
import PasswordDialog from "@/components/auth/PasswordDialog";
import OnboardingGuide from "@/components/onboarding/OnboardingGuide";

export default {
  name: "App",
  components: {
    OnboardingGuide,
    PasswordDialog,
    GlobalAlert,
    GlobalDialog,
    FormLine,
    ...layouts,
  },
  async created() {
    this.$store.commit(
      "calendar/SET_DATE",
      getDate(undefined, false).format("YYYY-MM-DD")
    );
  },
  computed: {
    ...mapState({
      isGlobalLoading: (state) => state.isGlobalLoading,
    }),
    layout() {
      return `${this.$route.meta.layout || "Default"}Layout`;
    },
    layoutProps() {
      return this.$route.meta.layoutProps;
    },
  },
  methods: {
    ...mapActions({ deleteUserFromVenue: "venues/deleteUserFromVenue" }),
  },
};
</script>

<style lang="scss" scoped>
.global-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
}
</style>
