<template>
  <div class="auth-layout">
    <div class="auth-layout__content" :class="{ 'm-wide': isWide }">
      <slot />
    </div>
    <div class="auth-layout__actions">
      <LogoutButton v-if="hasLogout" />
    </div>
  </div>
</template>

<script>
import LogoutButton from "@/components/auth/LogoutButton";
export default {
  name: "AuthLayout",
  components: { LogoutButton },
  props: {
    hasLogout: {
      type: Boolean,
      default: false,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-layout {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  height: 100%;
  width: 100%;

  &__content {
    padding: 62px 24px;
    width: 468px;

    &.m-wide {
      width: 800px;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: $media-tablet) {
      top: 24px;
      right: 24px;
    }

    @media (min-width: $media-laptop) {
      top: 32px;
      right: 42px;
    }
  }
}
</style>
