import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import moment from "moment";

const state = {
  date: moment().format("YYYY-MM-DD"),
  view: "week",
  month: moment().format("YYYY-MM"),
  calendar: {},
  groups: [],
  editingGroup: null,
  productIds: [],
  highlightedGroupId: null,
  isDrawerOpen: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
