export default {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_EDITED_ITEM(state, item) {
    state.editedItem = item;
  },
  SET_LAST_DOC(state, doc) {
    state.lastDoc = doc;
  },
  SET_ACTIVE_SORT(state, sort) {
    state.activeSort = sort;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
  SET_SEARCH_FIELD(state, payload) {
    state.searchField = payload;
  },
  SET_SIGNATURE_TYPE(state, payload) {
    state.signatureType = payload;
  },
  SET_BOOKING_REFERENCE(state, payload) {
    state.bookingReference = payload;
  },
  SET_PERIOD_FILTER(state, payload) {
    state.periodFilter = payload;
  },
  SET_WAIVER_ID_FILTER(state, payload) {
    state.waiverIdFilter = payload;
  },
  SET_IS_SORT_ENABLED(state, payload) {
    state.isSortEnabled = payload;
  },
};
