export default {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_EDITED_ITEM(state, item) {
    state.editedItem = item;
  },
  SET_VENUE_USER_JUNCTION(state, junction) {
    state.venueUserJunction = junction;
  },
  SET_IS_SUPERADMIN(state, isSuperadmin) {
    state.isSuperadmin = isSuperadmin;
  },
  SET_ROLE_FILTER(state, filter) {
    state.roleFilter = filter;
  },
  SET_SORT(state, sort) {
    state.sort = sort;
  },
  SET_CURRENT_ONBOARDING_STEP(state, step) {
    state.currentOnboardingStep = step;
  },
  SET_IS_ONBOARDING_FINISHED(state, isFinished) {
    state.isOnboardingFinished = isFinished;
  },
  SET_IS_SKIP_BANK_ACCOUNT_SETUP(state, isSkip) {
    state.isSkipBankAccountSetup = isSkip;
  },
};
