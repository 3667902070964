import moment from "moment";
import { getAllCountries, getTimezone } from "countries-and-timezones";
import sanitizeHtml from "sanitize-html";
import {
  MoneyFormatVariablesEnum,
  RangeButtonsEnum,
  WidgetPositionEnum,
  WidgetThemeEnum,
} from "@/helpers/enums";
import store from "@/store";
import { DEFAULT_MONEY_FORMAT } from "@/helpers/const";
import dialog from "@/plugins/dialog";
import momentTZ from "moment-timezone";

export const generateWidgetCode = ({
  venueId,
  productId,
  position,
  isPromocodesAvailable,
  widgetTheme,
  isHideBeacon,
  view,
  widgetTemplateId,
}) => {
  const root = document.createElement("div");
  if (
    position === WidgetPositionEnum.EMBEDDED ||
    widgetTheme === WidgetThemeEnum.BADAXE
  ) {
    const embedDiv = document.createElement("div");
    embedDiv.id = "reserve-lane";
    root.appendChild(embedDiv);
  }
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `${process.env.VUE_APP_WIDGET_BASE_URL}/js/app.js`;
  script.setAttribute("data-venue-id", venueId);
  if (productId) {
    script.setAttribute("data-product-id", productId);
  }
  if (position) {
    script.setAttribute("data-position", position);
  }
  if (typeof isPromocodesAvailable !== "undefined") {
    script.setAttribute(
      "data-promocodes",
      (+!!isPromocodesAvailable).toString(),
    );
  }
  if (
    position === WidgetPositionEnum.FLOATING &&
    typeof isHideBeacon !== "undefined"
  ) {
    script.setAttribute("data-hide-beacon", (+!!isHideBeacon).toString());
  }
  if (view) {
    script.setAttribute("data-view", JSON.stringify(view));
  }

  if (widgetTemplateId) {
    script.setAttribute("data-widget-template-id", widgetTemplateId);
  }

  root.appendChild(script);
  return root.innerHTML;
};

export const generateWidgetPreviewLink = ({
  venueId,
  productId,
  position,
  isPromocodesAvailable,
  isHideBeacon,
  view,
  widgetTemplateId,
  isAlwaysOpen,
}) => {
  const url = new URL(process.env.VUE_APP_WIDGET_BASE_URL);
  url.searchParams.set("venueId", venueId);
  if (productId) {
    url.searchParams.set("productId", productId);
  }
  if (position) {
    url.searchParams.set("position", position);
  }
  if (
    position === WidgetPositionEnum.FLOATING &&
    typeof isHideBeacon !== "undefined"
  ) {
    url.searchParams.set("hideBeacon", (+!!isHideBeacon).toString());
  }

  if (typeof isPromocodesAvailable !== "undefined") {
    url.searchParams.set("promocodes", (+!!isPromocodesAvailable).toString());
  }

  if (view) {
    url.searchParams.set("view", encodeURIComponent(JSON.stringify(view)));
  }

  if (widgetTemplateId) {
    url.searchParams.set("widgetTemplateId", widgetTemplateId);
  }

  if (isAlwaysOpen) {
    url.searchParams.set("isAlwaysOpen", (+!!isAlwaysOpen).toString());
  }

  return url.toString();
};

export const generateWidgetWaiverGenericLink = (venue) => {
  return `${process.env.VUE_APP_WIDGET_BASE_URL}/widget-signature?venueId=${venue.id}`;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getHourName = (hour, minutes) => {
  hour %= 24;
  let start = hour;
  const hoursFormat = getVenueTimeFormatHours();
  if (hoursFormat === 12) {
    start = hour && (hour % 12 || 12);
  }
  const daytime = hoursFormat === 12 ? (hour < 12 ? "am" : "pm") : "";
  return `${start > 9 || hoursFormat === 12 ? start : `0${start}`}${
    minutes
      ? `:${minutes > 9 ? minutes : `0${minutes}`}`
      : hoursFormat === 24
      ? ":00"
      : ""
  }${daytime}`;
};

export const getMinutes = (hour) => {
  return hour.hours * 60 + hour.minutes;
};

export const getSlotFromMinutes = (minutes) => {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
};

export const getSlotTimeFromMinutes = (minutes) => {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
};

export const getFormattedSlots = (booking, timezone) => {
  if (!booking) return "";
  const slots = [];

  booking.slots.forEach((baseSlot) => {
    const offset = getTimezoneOffsetHours(timezone);
    const baseSlotWithTimeZone = {
      ...baseSlot,
      from: getSlotTimeFromMinutes(getMinutes(baseSlot.from) + offset * 60),
      to: getSlotTimeFromMinutes(getMinutes(baseSlot.to) + offset * 60),
    };
    const lastSlot = slots[slots.length - 1];
    if (!slots.length) {
      slots.push(baseSlotWithTimeZone);
    } else if (!Array.isArray(lastSlot)) {
      if (
        getMinutes(lastSlot.to) ===
        getMinutes(baseSlotWithTimeZone.from) - baseSlotWithTimeZone.breakTime
      ) {
        slots[slots.length - 1] = [lastSlot, baseSlotWithTimeZone];
      } else {
        slots.push(baseSlotWithTimeZone);
      }
    } else if (
      getMinutes(lastSlot[1].from) === getMinutes(baseSlotWithTimeZone.from)
    ) {
      slots[slots.length - 1][1] = baseSlotWithTimeZone;
    } else {
      slots.push(baseSlotWithTimeZone);
    }
  });

  return slots
    .map((slot) => {
      if (!Array.isArray(slot)) {
        return getHourName(slot.from.hours, slot.from.minutes);
      } else
        return `${getHourName(
          slot[0].from.hours,
          slot[0].from.minutes,
        )}-${getHourName(slot[1].from.hours, slot[1].from.minutes)}`;
    })
    .join(", ");
};

export const getFormattedBookingTime = (booking) => {
  return !booking ? "" : moment.utc(booking.dateIso).format("ddd MMM D, YYYY");
};

export const getTimeDifference = ({
  current,
  previous,
  recentMinutes = 20,
  recentString = "new",
}) => {
  const msPerMinute = 60 * 1000;
  const msRecent = msPerMinute * recentMinutes;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msRecent) {
    return recentString;
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + "min ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + "h ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + "d ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + "m ago";
  } else {
    return Math.round(elapsed / msPerYear) + "y ago";
  }
};

export const getTimeDifferenceTillDay = (current, previous) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return "new";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + "min ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + "h ago";
  } else {
    return "";
  }
};

export const getSortedCountries = () => {
  const countries = Object.values(getAllCountries());
  const usa = countries.find((city) => city.id === "US");
  const canada = countries.find((city) => city.id === "CA");
  return [canada, usa];
};

const US = { code: "USD", symbol: "$" };
const CA = { code: "CAD", symbol: "$" };
export const getCurrencyByCountryCode = (countryCode) => {
  return { US, CA }[countryCode] || US;
};

export const getTimezoneOffsetHours = (timezone) => {
  return (getTimezone(timezone)?.utcOffset || 0) / 60;
};

export const sanitize = (text) => {
  return sanitizeHtml(text, {
    allowedTags: false,
    allowedAttributes: false,
    allowedSchemes: ["http", "https"],
    allowedSchemesByTag: {
      img: ["data", "https"],
    },
  });
};

export const getBookingPrice = (checkoutInfo) => {
  if (!checkoutInfo || !checkoutInfo.payments.length) return {};

  const {
    total,
    subtotal,
    taxes,
    upsellItems,
    upsellTaxes,
    discounts,
    depositPrice,
    isFullFirstPayment,
    affiliateCommissionAmount,
    appliedPromocode,
  } = checkoutInfo;
  const paymentMethodAlias =
    checkoutInfo.payments[checkoutInfo.payments.length - 1]?.paymentMethodAlias;
  return {
    total,
    depositPrice,
    subtotal,
    taxes,
    upsellItems,
    upsellTaxes,
    discounts,
    paymentMethodAlias,
    isFullFirstPayment,
    affiliateCommissionAmount,
    appliedPromocode,
    ...checkoutInfo.payments.reduce(
      (previous, current) => {
        const paid = previous.paid + (current.paid || 0);
        const refunded = previous.refunded + (current.refunded || 0);
        const availableForRefund =
          previous.availableForRefund +
          (current.paymentIntentId
            ? (current.paid || 0) - (current.refunded || 0)
            : 0);
        return {
          paid,
          refunded,
          availableForRefund,
        };
      },
      {
        paid: 0,
        refunded: 0,
        availableForRefund: 0,
      },
    ),
  };
};

export const getDate = (day = undefined, isISO = true) => {
  const date = moment(day);
  return isISO ? date.toISOString() : date;
};

export const checkActive = (date, baseDate = false) => {
  return baseDate !== false ? baseDate === date : getDate() === date;
};

export const getNumberWithZero = (num) => {
  return num >= 10 ? num : `0${num}`;
};

export const getAvailabilitySlotTime = (hours, isAm = true) => {
  const hoursFormat = getVenueTimeFormatHours();
  if (hoursFormat === 12 && isAm && +hours === 12) return 0;
  else if (isAm) return +hours;
  else if (+hours === 12) return 12;
  else return +hours + 12;
};

export const getAvailabilitySave = (slot, utcOffset = 0) => {
  const [startHour, startMinute] = slot.startTime?.split(":") || [];
  const [durationHour, durationMinute] = slot.duration?.split(":") || [];
  const [breakTimeHour, breakTimeMinute] = slot.breakTime?.split(":") || [];
  if (
    startHour &&
    startMinute &&
    durationHour &&
    durationMinute &&
    breakTimeHour &&
    breakTimeMinute
  ) {
    const hours = getAvailabilitySlotTime(
      startHour,
      !slot.activeTime || slot.activeTime === "am",
    );
    const startMinutes = hours * 60 + +startMinute - utcOffset * 60;
    const durationMinutes = +durationHour * 60 + +durationMinute;
    const breakTimeMinutes = +breakTimeHour * 60 + +breakTimeMinute;
    const from = getSlotTimeFromMinutes(startMinutes);
    const to = getSlotTimeFromMinutes(startMinutes + durationMinutes);
    const isPrivate = slot.isPrivate ?? false;
    const isVisibleOnline = slot.isVisibleOnline ?? false;

    const processPrice = (price) =>
      (typeof price === "string" ? +price.replace(/[CA$,]/g, "") : price) ||
      null;

    return {
      from,
      to,
      breakTime: breakTimeMinutes,
      price: processPrice(slot.price),
      fixedPrice: processPrice(slot.fixedPrice),
      groupsBasedPrice: slot.groupsBasedPrice || null,
      isPrivate,
      isVisibleOnline,
    };
  }

  return null;
};

export const getWalkInTimeSave = (slot, utcOffset) => {
  const [startHour, startMinute] = slot.from.time.split(":");
  const [endHour, endMinute] = slot.to.time.split(":");

  const startHours = getAvailabilitySlotTime(
    startHour,
    !slot.from?.activeTime || slot.from.activeTime === "am",
  );
  const startMinutes = startHours * 60 + +startMinute - utcOffset * 60;
  const from = getSlotTimeFromMinutes(startMinutes);

  const endHours = getAvailabilitySlotTime(
    endHour,
    !slot.to?.activeTime || slot.to.activeTime === "am",
  );
  const endMinutes = endHours * 60 + +endMinute - utcOffset * 60;
  const to = getSlotTimeFromMinutes(endMinutes);

  return {
    from,
    to,
  };
};

export const getCheckoutUrls = (url) => {
  const href = url || window.location.href;
  const cancelUrl = new URL(href);
  cancelUrl.searchParams.set("bookingPaymentStatus", "error");
  const successUrl = new URL(href);
  successUrl.searchParams.set("bookingPaymentStatus", "success");
  return { cancelUrl, successUrl };
};

export const getDateRangeByType = (rangeType, format) => {
  const nowMoment = moment();
  const nowString = nowMoment.format(format);
  if (rangeType === RangeButtonsEnum.TODAY) {
    return [nowString, nowString];
  } else if (rangeType === RangeButtonsEnum.WEEK) {
    return [nowMoment.add(-1, "week").format(format), nowString];
  } else if (rangeType === RangeButtonsEnum.THIS_WEEK) {
    return [
      nowMoment.startOf("week").format(format),
      nowMoment.endOf("week").format(format),
    ];
  } else if (rangeType === RangeButtonsEnum.MONTH) {
    return [nowMoment.add(-1, "month").format(format), nowString];
  } else if (rangeType === RangeButtonsEnum.THIS_MONTH) {
    return [
      nowMoment.startOf("month").format(format),
      nowMoment.endOf("month").format(format),
    ];
  } else if (rangeType === RangeButtonsEnum.YEAR) {
    return [nowMoment.add(-1, "year").format(format), nowString];
  } else if (rangeType === RangeButtonsEnum.THIS_YEAR) {
    return [
      nowMoment.startOf("year").format(format),
      nowMoment.endOf("year").format(format),
    ];
  }
  return null;
};

export const getVenueTimeFormatHours = () => {
  return store.state.venues.selectedVenue?.timeFormat || 12;
};
export const getVenueMoneyFormat = () => {
  return store.state.venues.selectedVenue?.moneyFormat || DEFAULT_MONEY_FORMAT;
};

export const getVenueTimeFormat = () => {
  const hoursFormat = getVenueTimeFormatHours();
  if (hoursFormat === 24) {
    return "HH:mm";
  }
  return "h:mma";
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatMoney = (amount, template = DEFAULT_MONEY_FORMAT) => {
  const regex = /[^{{]+(?=}})/g;
  const matches = [...new Set(template.match(regex))].filter((variable) =>
    Object.values(MoneyFormatVariablesEnum).includes(variable),
  );
  const variablesValuesMap = {};
  matches.forEach((variable) => {
    const formatter = new Intl.NumberFormat("en-US", {
      useGrouping: true,
      maximumFractionDigits: {
        [MoneyFormatVariablesEnum.AMOUNT]: 2,
        [MoneyFormatVariablesEnum.AMOUNT_ROUNDED]: 0,
      }[variable],
    });
    variablesValuesMap[variable] = formatter.format(amount);
  });
  let result = template;
  Object.entries(variablesValuesMap).forEach(([variable, value]) => {
    result = result.replace(new RegExp(`{{${variable}}}`, "g"), value);
  });
  return result;
};

const isTestStripe =
  process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY?.startsWith("pk_test_");
export const getStripeDashboardPaymentIntentLink = (
  accountId,
  paymentIntentId,
) => {
  return `https://dashboard.stripe.com/${
    isTestStripe ? "test/" : ""
  }connect/accounts/${accountId}/payments/${paymentIntentId}`;
};

export const alertRefundResult = async (refundResult) => {
  const timeFormat = getVenueTimeFormat();
  const moneyFormat = getVenueMoneyFormat();
  const refundTime = moment().format(`M/D/YYYY @ ${timeFormat}`);
  await dialog.alert({
    title: "Done!",
    message: `<p class="mb-24">
              The following refunds have been processed:<br/> 
              ${refundResult.paymentIntents
                .map(
                  (payment) =>
                    `${formatMoney(
                      payment.amount / 100,
                      moneyFormat,
                    )} refunded${
                      payment.paymentMethodAlias
                        ? ` to ${payment.paymentMethodAlias}`
                        : ""
                    }`,
                )
                .join("<br />")} 
              </p>
              <span style="font-size: 12px;">Refunded on ${refundTime}</span>`,
    okText: "Done",
    isHtml: true,
  });
};

export function convertLocalToVenueTimezone(localDt, localDtFormat, venue) {
  const is24hFormat = venue.timeFormat === 24;
  const dateFormat = venue.dateFormat || "MM/DD/YY";

  return momentTZ(localDt, localDtFormat)
    .tz(venue.timezone)
    .format(`${dateFormat} ${is24hFormat ? "HH:mm" : "hh:mm A"}`);
}
