<template>
  <div>
    <v-tour
      :name="tourName"
      :steps="steps"
      :options="options"
      :callbacks="callbacks"
      class="onboarding-guide"
    >
      <template v-slot="tour">
        <div
          v-if="
            tour.steps[tour.currentStep] && tour.steps[tour.currentStep].isFixed
          "
          class="onboarding-guide__overlay"
        />
        <transition name="fade">
          <v-step
            v-if="isVisible && tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
            class="onboarding-popup"
            :class="{
              'onboarding-popup--fixed': tour.steps[tour.currentStep].isFixed,
              'onboarding-popup--no-arrow':
                tour.steps[tour.currentStep].isArrowDisabled,
            }"
            :style="{ minWidth: tour.steps[tour.currentStep].minWidth }"
          >
            <div slot="header" class="onboarding-popup__header">
              {{ tour.steps[tour.currentStep].title }}
              <Icon
                name="close"
                color="secondary-100"
                is-clickable
                class="onboarding-popup__close"
                @click="tour.stop"
              />
            </div>
            <div slot="content" class="onboarding-popup__content">
              {{ tour.steps[tour.currentStep].content }}
            </div>
            <div slot="actions" class="onboarding-popup__footer">
              <span class="onboarding-popup__progress">
                {{ tour.currentStep + 1 }} of {{ tour.steps.length }}
              </span>
              <div class="onboarding-popup__actions">
                <button
                  v-if="!tour.isFirst"
                  class="onboarding-popup__back"
                  @click="tour.previousStep"
                >
                  Back
                </button>
                <template v-if="!tour.steps[tour.currentStep].waitForAction">
                  <button
                    v-if="!tour.isLast"
                    class="onboarding-popup__next"
                    @click="tour.nextStep"
                  >
                    Next step
                    <Icon
                      name="arrow-forward"
                      color="secondary-500"
                      :size="14"
                    />
                  </button>
                  <button
                    v-else
                    class="onboarding-popup__next"
                    @click="tour.finish"
                  >
                    Finish
                  </button>
                </template>
                <button
                  v-else-if="tour.steps[tour.currentStep].isSkippable"
                  class="onboarding-popup__next"
                  @click="handleSkipStep(tour.steps[tour.currentStep])"
                >
                  Skip step
                  <Icon name="arrow-forward" color="secondary-500" :size="20" />
                </button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { getOnboardingGuideSteps } from "@/helpers/onboardingSteps";
import { mapActions } from "vuex";

export default {
  name: "OnboardingGuide",
  data() {
    return {
      tourName: "onboardingGuide",
      currentUser: auth.currentUser,
      options: {
        highlight: true,
      },
      callbacks: {
        onStart: this.onStart,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep,
        onFinish: this.onFinish,
        onStop: this.onStop,
      },
      isVisible: true,
    };
  },
  computed: {
    steps() {
      return getOnboardingGuideSteps(this.user?.displayName || "Friend").map(
        (step, index) => ({
          target: `[data-v-step="${index}"]`,
          ...step,
        })
      );
    },
    user() {
      return this.currentUser;
    },
  },
  methods: {
    ...mapActions({
      updateOnboardingProgress: "users/updateOnboardingProgress",
    }),
    handleStepAsyncOperations(step) {
      if (step?.waitForAction) {
        step.waitForAction().then(() => {
          this.isVisible = false;
          setTimeout(() => {
            this.$tours[this.tourName].nextStep();
          }, 500);
        });
      }
      if (step?.before) {
        this.isVisible = false;
        step.before().then(() => {
          setTimeout(() => {
            this.isVisible = true;
          }, 500);
        });
      }
    },
    async onStart() {
      this.isVisible = false;
      setTimeout(async () => {
        const currentStep = this.$tours[this.tourName].currentStep;
        this.handleStepAsyncOperations(this.steps[currentStep]);
        this.isVisible = true;
        await this.updateOnboardingProgress({
          currentStep,
        });
      }, 500);
    },
    async onFinish() {
      await this.updateOnboardingProgress({
        isFinished: true,
        currentStep: -1,
      });
    },
    async onNextStep(currentStepIndex) {
      const nextStep = this.steps[currentStepIndex + 1];
      this.handleStepAsyncOperations(nextStep);

      await this.updateOnboardingProgress({
        currentStep: currentStepIndex + 1,
      });
    },
    async onPreviousStep(currentStepIndex) {
      const prevStep = this.steps[currentStepIndex - 1];
      this.handleStepAsyncOperations(prevStep);
      await this.updateOnboardingProgress({
        currentStep: currentStepIndex - 1,
      });
    },
    onStop() {
      const currentStep = this.$tours[this.tourName].currentStep;
      const step = this.steps[currentStep];
      this.handleSkipStep(step);
    },
    handleSkipStep(step) {
      if (step?.resolveAction) {
        step.resolveAction();
      }
    },
  },
};
</script>

<style lang="scss">
.v-tour--active {
  pointer-events: initial !important;

  .sidebar {
    pointer-events: none !important;
  }
}

.v-tour__target--highlighted {
  transition: 0.3s;
  border-radius: 4px;
  padding: 4px;
  outline: 2px solid $primary !important;
  box-shadow: inherit !important;
}

.onboarding-popup {
  &--fixed,
  &--no-arrow {
    .v-step__arrow {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.onboarding-guide {
  .onboarding-popup {
    padding: 24px 16px;
    background: $primary;
    // min-width: 288px;
    // max-width: 300px;
    min-width: 233px;
    max-width: 238px;
    border-radius: 20px;

    @media (min-width: $media-tablet) {
      min-width: 325px;
      padding: 24px 18px;
    }

    @media (min-width: $media-laptop) {
      min-width: 532px;
      padding: 32px 40px;
    }

    &--fixed {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      z-index: 350;
    }

    &__close {
      position: absolute;
      left: 100%;
      top: -12px;
    }

    &__header {
      position: relative;
      color: $secondary-100;
      margin: 0 20px 10px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }

    &__content {
      color: $secondary-100;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 28px;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__back {
      border: none;
      background: transparent;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $secondary-100;
      cursor: pointer;
      transition: 0.3s;
      padding: 0;

      &:hover {
        color: $secondary-300;
      }
    }

    &__next {
      border: none;
      background: $secondary-100;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $secondary-500;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 4px 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;

      @media (min-width: $media-laptop) {
        padding: 15px 32px;
      }

      &:hover {
        background: $secondary-200;
      }
    }

    &__progress {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $secondary-100;
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    z-index: 300;
  }
}
</style>
