import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { UsersSortEnum } from "@/helpers/enums";

const state = {
  list: [],
  editedItem: null,
  roleFilter: null,
  sort: UsersSortEnum.AZ,
  currentOnboardingStep: -1,
  isOnboardingFinished: false,

  venueUserJunction: null,
  isSuperadmin: null,
  isSkipBankAccountSetup: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
