<template>
  <button class="logout-button" @click="logout">
    <Icon name="sign-out" />
    Log out
  </button>
</template>

<script>
import { logout } from "@/helpers/firebase-helpers";

export default {
  name: "LogoutButton",
  methods: {
    logout,
  },
};
</script>

<style lang="scss" scoped>
.logout-button {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px 24px;
  color: $secondary-400;
  transition: 0.3s;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-family: $font-family;

  svg {
    fill: $secondary-400 !important;
    transition: 0.3s;
  }

  &:hover {
    color: $danger;

    svg {
      fill: $danger !important;
    }
  }
}
</style>
