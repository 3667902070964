import {
  getCollection,
  getDocById,
  getPaginatedCollection,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { orderBy, Timestamp, where } from "firebase/firestore";
import {
  BookingFilterEnum,
  BookingPageViewEnum,
  BookingSearchFieldEnum,
  BookingStatusEnum,
  BookingTableSortFieldEnum,
  LogTypeEnum,
  SortDirectionEnum,
} from "@/helpers/enums";
import BookingService from "@/api/services/BookingService";
import moment from "moment";
import { createDocument } from "@/helpers/firebase-helpers";
import { auth } from "@/plugins/firebase";
import { getTimezoneOffsetHours } from "@/helpers/utils";

export default {
  async fetchBookings(
    { state, commit, rootState },
    { limit: limitValue, withPagination = true }
  ) {
    if (rootState.venues.selectedVenue) {
      const filterConstraints =
        state.activeFilter === BookingFilterEnum.REFUNDED
          ? [where("isRefunded", "==", true)]
          : state.activeFilter === BookingFilterEnum.WARNING
          ? [where("isWarning", "==", true)]
          : state.activeFilter in BookingStatusEnum
          ? [where("status", "==", state.activeFilter)]
          : [];
      let [from, to] = state.dateRangeFilter;
      if (from && !to) {
        to = from;
      }

      const fromOffset = getTimezoneOffsetHours(
        rootState.venues.selectedVenue.timezone
      );
      const possibleFromMoments = [
        state.activeFilter === BookingFilterEnum.UPCOMING && moment.utc(),
        from && moment.utc(from).add(-fromOffset, "h"),
      ].filter(Boolean);
      const fromMoment = possibleFromMoments.length
        ? moment.max(possibleFromMoments)
        : null;
      const fromDate = fromMoment?.isValid() ? fromMoment.toDate() : undefined;
      const toOffset = getTimezoneOffsetHours(
        rootState.venues.selectedVenue.timezone
      );
      const possibleToMoments = [
        state.activeFilter === BookingFilterEnum.ENDED && moment.utc(),
        to ? moment.utc(to).endOf("date").add(-toOffset, "h") : undefined,
      ].filter(Boolean);
      const toMoment = possibleToMoments.length
        ? moment.min(possibleToMoments)
        : null;
      const toDate = toMoment?.isValid() ? toMoment.toDate() : undefined;
      const dateField =
        state.selectedView === BookingPageViewEnum.COMPACT
          ? BookingTableSortFieldEnum.CREATION_DATE
          : state.activeSortField;
      const orderDirection =
        state.selectedView === BookingPageViewEnum.COMPACT
          ? SortDirectionEnum.DESC
          : state.activeSortDirection;
      const dateConstraints = [
        fromDate && where(dateField, ">=", fromDate),
        toDate && where(dateField, "<=", toDate),
        orderBy(dateField, orderDirection),
      ].filter(Boolean);

      let searchConstraint = null;
      if (state.searchQuery && state.searchField) {
        const searchValue =
          state.searchField === BookingSearchFieldEnum.PHONE
            ? state.searchQuery.replace(/[^\d+]/g, "")
            : state.searchQuery;
        searchConstraint = where(state.searchField, "==", searchValue);
      }

      const fetchNumberOfSignedWaivers = (list) =>
        Promise.allSettled(
          list.map(async (booking) => {
            if (booking.activeWaiver) {
              const signatures = await getCollection(
                `/venues/${rootState.venues.selectedVenue.id}/products/${booking.productId}/signatures`,
                where("bookingId", "==", booking.id)
              );
              booking.numberOfSignedWaivers = signatures.length;
            }
          })
        );

      const isIgnoreAllFilters =
        !!state.searchQuery &&
        state.searchField === BookingSearchFieldEnum.REGISTRATION_CODE;

      if (withPagination) {
        const queryConstraints = [
          searchConstraint,
          ...(!isIgnoreAllFilters ? [...filterConstraints] : []),
          ...(!isIgnoreAllFilters ? [...dateConstraints] : []),

          state.activeFilter === BookingFilterEnum.SAVED &&
            where("isSaved", "==", true),
          where(
            "status",
            "in",
            isIgnoreAllFilters
              ? [
                  BookingFilterEnum.ABANDONED,
                  BookingFilterEnum.CANCELED,
                  BookingFilterEnum.WAIT_FOR_PAYMENT,
                  BookingFilterEnum.PAID,
                ]
              : state.activeFilter === BookingFilterEnum.ABANDONED
              ? [BookingFilterEnum.ABANDONED]
              : state.activeFilter === BookingFilterEnum.CANCELED
              ? [BookingFilterEnum.CANCELED]
              : state.activeFilter === BookingFilterEnum.UPCOMING
              ? [BookingFilterEnum.WAIT_FOR_PAYMENT, BookingFilterEnum.PAID]
              : [BookingFilterEnum.WAIT_FOR_PAYMENT, BookingFilterEnum.PAID]
          ),
          ...(rootState.bookings.activeProductFilter
            ? [where("productId", "==", rootState.bookings.activeProductFilter)]
            : []),
        ];
        const { data: bookings, lastDoc } = await getPaginatedCollection(
          `/venues/${rootState.venues.selectedVenue.id}/bookings`,
          state.lastDoc,
          limitValue,
          ...queryConstraints
        );

        if (!lastDoc) return 0;

        commit("SET_LAST_DOC", lastDoc);

        await fetchNumberOfSignedWaivers(bookings);

        if (!state.list.length) {
          commit("SET_LIST", bookings);
        } else {
          commit("SET_LIST", [...state.list, ...bookings]);
        }

        return bookings.length;
      } else {
        const { bookings, length } = await BookingService.getDetailedBookings({
          activeFilter: isIgnoreAllFilters ? null : state.activeFilter,
          productId: rootState.bookings.activeProductFilter,
          dateRangeFilter: isIgnoreAllFilters ? [] : state.dateRangeFilter,
          searchField: state.searchField,
          searchQuery: state.searchQuery,
        });

        const flatBookings = Object.values(bookings).flatMap((date) =>
          Object.values(date).flatMap((startTime) =>
            Object.values(startTime).flatMap((endTime) => endTime.bookings)
          )
        );
        await fetchNumberOfSignedWaivers(flatBookings);

        commit("SET_DETAILED_LIST_LENGTH", length);
        commit("SET_DETAILED_LIST", bookings);
      }
    } else {
      commit("SET_LIST", []);
    }
  },
  resetBookings({ commit }) {
    commit("SET_LIST", []);
    commit("SET_LAST_DOC", undefined);
  },
  async fetchBookingById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue) {
      const booking = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        id
      );
      commit("SET_EDITED_ITEM", booking);
    }
  },
  async fetchBookingPaymentMethods({ commit, rootState, state }) {
    if (
      rootState.venues.selectedVenue &&
      rootState.products.selectedProduct &&
      state.editedItem
    ) {
      const methods = await BookingService.getBookingPaymentMethods(
        state.editedItem.id
      );
      commit("SET_EDITED_ITEM_PAYMENT_METHODS", methods);
    }
  },
  async fetchSignatures({ rootState, state, commit }) {
    if (
      rootState.venues.selectedVenue &&
      rootState.products.selectedProduct &&
      state.editedItem
    ) {
      const signatures = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        where("bookingId", "==", state.editedItem.id)
      );
      commit("SET_SIGNATURES", signatures);
    }
  },
  async fetchSignatureById({ rootState, commit }, id) {
    if (rootState.venues.selectedVenue && rootState.products.selectedProduct) {
      const signature = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/products/${rootState.products.selectedProduct.id}/signatures`,
        id
      );
      const booking =
        signature.bookingId &&
        (await getDocById(
          `/venues/${rootState.venues.selectedVenue.id}/bookings`,
          signature.bookingId
        ));
      if (booking) {
        signature.bookingRegistrationCode = booking.registrationCode;
      }
      commit("SET_DETAILED_SIGNATURE", signature);
    }
  },
  async getFreeSlots({ commit }, { date, playersCount, bookingId, productId }) {
    const { allSlots, freeSlots } = await BookingService.getFreeSlots({
      productId,
      date,
      playersCount: +(playersCount || 1),
      bookingId,
    });
    commit("SET_FREE_SLOTS", freeSlots);
    commit("SET_ALL_SLOTS", allSlots);
  },
  async createBooking(
    _,
    {
      chosenSlots,
      chosenDate,
      playersCount,
      productId,
      isWalkIn,
      isConfirmationEmail,
      selectedGroupId,
      selectedGroup,
      typeOfPricing,
      withoutPaymentOption,
      affiliateId,
      isAllowOverbook,
    }
  ) {
    return await BookingService.createBooking({
      productId,
      slots: chosenSlots,
      date: chosenDate,
      playersCount,
      isWalkIn,
      isConfirmationEmail,
      selectedGroupId,
      selectedGroup,
      typeOfPricing,
      withoutPaymentOption,
      affiliateId,
      isAllowOverbook,
    });
  },
  async editBooking(
    {
      state: {
        editedItem: { id: bookingId },
      },
    },
    payload
  ) {
    return await BookingService.editBooking({
      bookingId,
      ...payload,
    });
  },
  async sendEmail(
    {
      state: {
        editedItem: { id: bookingId },
      },
    },
    { email, type, subject, text, templateId }
  ) {
    return await BookingService.sendEmail({
      bookingId,
      email,
      type,
      subject,
      text,
      templateId,
    });
  },
  async updateBooking(
    { rootState, state, commit },
    { id, data, replaceBooking = false, replaceDetailedBooking, detailedData }
  ) {
    if (rootState.venues.selectedVenue) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        id,
        data
      );

      if (replaceBooking) {
        const oldList = [...state.list];
        const index = oldList.findIndex((booking) => booking.id === id);
        oldList[index] = { ...oldList[index], ...data };
        commit("SET_LIST", oldList);
      }
      if (replaceDetailedBooking) {
        const oldList = [
          ...state.detailedList[detailedData.date][detailedData.startKey][
            detailedData.endKey
          ].bookings,
        ];
        const booking = oldList.find((booking) => booking.id === id);
        const index = oldList.findIndex((booking) => booking.id === id);
        oldList[index] = {
          ...booking,
          isSaved: data.isSaved,
        };
        commit("SET_DETAILED_LIST", {
          ...state.detailedList,
          [detailedData.date]: {
            ...state.detailedList[detailedData.date],
            [detailedData.startKey]: {
              ...state.detailedList[detailedData.date][detailedData.startKey],
              [detailedData.endKey]: {
                ...state.detailedList[detailedData.date][detailedData.startKey][
                  detailedData.endKey
                ],
                bookings: oldList,
              },
            },
          },
        });
      }
    }
  },
  async saveAffectedBookings({ rootState, dispatch }) {
    if (rootState.venues.selectedVenue) {
      const bookings = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/bookings`,
        where("isSaved", "==", false),
        where("date", ">", moment.utc().toDate()),
        where("status", "in", [
          BookingStatusEnum.PAID,
          BookingStatusEnum.WAIT_FOR_PAYMENT,
        ])
      );

      if (bookings?.length) {
        await Promise.allSettled(
          bookings.map((booking) =>
            dispatch("updateBooking", {
              id: booking.id,
              data: { isSaved: true },
            })
          )
        );

        return true;
      }
      return false;
    }
  },
  async addReservationInfo(_, { bookingId, reservationInfo }) {
    return await BookingService.addReservationInfo({
      bookingId,
      data: {
        ...reservationInfo,
        phone: reservationInfo.phone.replace(/[^\d+]/g, ""),
      },
    });
  },

  async checkout(_, { bookingId, data }) {
    const checkout = await BookingService.checkout({
      bookingId,
      data,
    });
    if (!data.isSkipPayment && checkout.url) {
      window.open(checkout.url, "_self");
    }
  },
  async getPrice(_, { productId, ...params }) {
    return await BookingService.getPrice({
      productId,
      params,
    });
  },
  async createLog({ rootState, state }, comment) {
    if (rootState.venues.selectedVenue && state.editedItem.id) {
      const userName = auth.currentUser.displayName;
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/bookings/${state.editedItem.id}/logs`,
        {
          type: LogTypeEnum.CUSTOM,
          text: comment,
          timestamp: Timestamp.now(),
          userName,
        }
      );
    }
  },
  async getLogs({ rootState, state }) {
    if (rootState.venues.selectedVenue && state.editedItem.id) {
      try {
        return await getCollection(
          `/venues/${rootState.venues.selectedVenue.id}/bookings/${state.editedItem.id}/logs`,
          orderBy("timestamp", "desc")
        );
      } catch (e) {
        console.log(e);
        return [];
      }
    }
  },

  async getDates({ rootState }, { playersCount, month, productId }) {
    if (rootState.venues.selectedVenue && productId) {
      return await BookingService.getDates({
        productId,
        bookingId: rootState.bookings?.editedItem?.id,
        playersCount,
        month,
      });
    }
  },
};
