import StatisticsService from "@/api/services/StatisticsService";
import moment from "moment";
import StripeService from "@/api/services/StripeService";
import { RangeButtonsEnum } from "@/helpers/enums";

const STATISTICS_CACHE_MINUTES = 60;

export default {
  async fetchStatistics({ commit, state }, isForceFetch) {
    const periodKey =
      state.periodFilter !== RangeButtonsEnum.CUSTOM
        ? state.periodFilter
        : state.dateRangeFilter.join(",");
    const lastFetchedAt = state.lastFetchedAt[periodKey];
    if (
      isForceFetch ||
      !lastFetchedAt ||
      moment.utc().diff(moment.utc(lastFetchedAt, "X"), "minutes") >
        STATISTICS_CACHE_MINUTES
    ) {
      const productId = state.productFilterId;
      const [from, to] = state.dateRangeFilter;
      const dateField = state.dateField;
      const statistics = await StatisticsService.getStatistics({
        productId,
        from,
        to,
        dateField,
      });
      commit("SET_STATISTICS", {
        ...state.statistics,
        [periodKey]: statistics,
      });
      commit("SET_LAST_FETCHED_AT", {
        ...state.lastFetchedAt,
        [periodKey]: moment.utc().unix(),
      });
    }
  },
  resetStatisticsCache({ commit, state }) {
    const resetLastFetchedAt = Object.keys(state.lastFetchedAt).reduce(
      (prev, key) => ({ ...prev, [key]: null }),
      {}
    );
    commit("SET_LAST_FETCHED_AT", resetLastFetchedAt);
  },
  async fetchDetailsList({ commit, state }, { statKey, limit }) {
    const productId = state.productFilterId;
    const [from, to] = state.dateRangeFilter;
    const dateField = state.dateField;
    const offset = state.detailsList.length;
    const list = await StatisticsService.getStatisticDetailsList(statKey, {
      productId,
      from,
      to,
      dateField,
      limit,
      offset,
    });
    const newList = offset ? [...state.detailsList, ...list] : list;
    commit("SET_DETAILS_LIST", newList);
    return list.length;
  },
  async fetchDetailsChart({ commit, state }, statKey) {
    const productId = state.productFilterId;
    const [from, to] = state.dateRangeFilter;
    const dateField = state.dateField;
    const chart = await StatisticsService.getStatisticDetailsChart(statKey, {
      productId,
      from,
      to,
      dateField,
    });
    commit("SET_DETAILS_CHART", chart);
  },
  async fetchStripeBalance({ commit }) {
    const balance = await StripeService.getBalance();
    commit("SET_STRIPE_BALANCE", balance);
  },
  async fetchPayouts({ commit, state }, limit) {
    const startingAfter = state.lastPayoutId;
    const response = await StripeService.getPayouts({ startingAfter, limit });
    const { hasMore, list } = response;
    if (startingAfter) {
      commit("SET_PAYOUTS", [...state.payouts, ...list]);
    } else {
      commit("SET_PAYOUTS", list);
    }
    if (list.length) {
      commit("SET_LAST_PAYOUT_ID", list[list.length - 1].id);
    }
    return { hasMore, length: list.length };
  },
  resetPayouts({ commit }) {
    commit("SET_PAYOUTS", []);
    commit("SET_LAST_PAYOUT_ID", null);
  },
};
