import Vue from "vue";
import VueRouter from "vue-router";
import { authMiddleware } from "@/router/middleware/authMiddleware";
import { onboardMiddleware } from "@/router/middleware/onboardMiddleware";
import {
  onboardBankAccountPathName,
  onboardVenuePathName,
} from "@/helpers/onboard";
import { permissionsMiddleware } from "@/router/middleware/permissionsMiddleware";
import { superadminMiddleware } from "@/router/middleware/superadminMiddleware";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  // Auth
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/LoginPage"),
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/auth/ForgotPasswordPage"),
    meta: {
      layout: "Auth",
    },
  },
  // Onboard
  {
    path: "/onboard/venue",
    name: onboardVenuePathName,
    component: () => import("@/views/settings/venues/CreateVenuePage"),
    meta: {
      authRequired: true,
      layout: "Auth",
      layoutProps: {
        hasLogout: true,
      },
    },
  },
  {
    path: "/onboard/bank-account",
    name: onboardBankAccountPathName,
    component: () =>
      import("@/views/settings/bank-accounts/CreateBankAccountPage"),
    meta: {
      authRequired: true,
      layout: "Auth",
      layoutProps: {
        hasLogout: true,
      },
    },
  },
  // Settings
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/settings/SettingsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/profile",
    name: "Profile",
    component: () => import("@/views/settings/ProfilePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/venue",
    name: "EditVenue",
    component: () => import("@/views/settings/venues/EditVenuePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/widget",
    redirect: "/settings/widget-templates",
  },
  {
    path: "/settings/widget-templates",
    name: "WidgetTemplates",
    component: () =>
      import("@/views/settings/widgetTemplates/WidgetTemplatesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/widget-templates/create",
    name: "CreateWidgetTemplate",
    component: () =>
      import("@/views/settings/widgetTemplates/CreateWidgetTemplatePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/widget-templates/:id",
    name: "EditWidgetTemplate",
    component: () =>
      import("@/views/settings/widgetTemplates/_id/EditWidgetTemplatePage"),
    meta: {
      authRequired: true,
    },
  },
  // {
  //   path: "/settings/refund-reserve",
  //   name: "RefundReserve",
  //   component: () => import("@/views/settings/RefundReservePage"),
  //   meta: {
  //     authRequired: true,
  //   },
  // },
  {
    path: "/settings/taxes",
    name: "Taxes",
    component: () => import("@/views/settings/taxes/TaxesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/taxes/create",
    name: "CreateTax",
    component: () => import("@/views/settings/taxes/CreateTaxPage"),
    meta: {
      authRequired: true,
      permission: "taxes.create",
    },
  },
  {
    path: "/settings/taxes/:id",
    name: "EditTax",
    component: () => import("@/views/settings/taxes/_id/EditTaxPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/mailing-templates",
    name: "MailingTemplates",
    component: () =>
      import("@/views/settings/mailing-templates/MailingTemplatesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/mailing-templates/create",
    name: "CreateMailingTemplate",
    component: () =>
      import("@/views/settings/mailing-templates/CreateMailingTemplatePage"),
    meta: {
      authRequired: true,
      permission: "mailingTemplates.create",
    },
  },
  {
    path: "/settings/mailing-templates/:id",
    name: "EditMailingTemplate",
    component: () =>
      import("@/views/settings/mailing-templates/_id/EditMailingTemplatePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/bank-accounts",
    name: "BankAccounts",
    component: () => import("@/views/settings/bank-accounts/BankAccountsPage"),
    meta: {
      authRequired: true,
      permission: "bankAccounts.view",
    },
  },
  {
    path: "/settings/bank-accounts/create",
    name: "CreateBankAccount",
    component: () =>
      import("@/views/settings/bank-accounts/CreateBankAccountPage"),
    meta: {
      authRequired: true,
      permission: "bankAccounts.create",
    },
  },
  {
    path: "/settings/terms-and-conditions",
    name: "Terms",
    component: () => import("@/views/settings/terms/TermsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/terms-and-conditions/create",
    name: "CreateTerms",
    component: () => import("@/views/settings/terms/CreateTermsPage"),
    meta: {
      authRequired: true,
      permission: "terms.create",
    },
  },
  {
    path: "/settings/terms-and-conditions/:id",
    name: "EditTerms",
    component: () => import("@/views/settings/terms/_id/EditTermsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/promocodes",
    name: "Promocodes",
    component: () => import("@/views/settings/promocodes/PromocodesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/promocodes/create",
    name: "CreatePromocode",
    component: () => import("@/views/settings/promocodes/CreatePromocodePage"),
    meta: {
      authRequired: true,
      permission: "promocodes.create",
    },
  },
  {
    path: "/settings/promocodes/:id",
    name: "EditPromocode",
    component: () =>
      import("@/views/settings/promocodes/_id/EditPromocodePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/affiliates",
    name: "Affiliates",
    component: () => import("@/views/settings/affiliates/AffiliatesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/affiliates/create",
    name: "CreateAffiliate",
    component: () => import("@/views/settings/affiliates/CreateAffiliatePage"),
    meta: {
      authRequired: true,
      permission: "affiliates.create",
    },
  },
  {
    path: "/settings/affiliates/:id",
    name: "EditAffiliate",
    component: () =>
      import("@/views/settings/affiliates/_id/EditAffiliatePage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/upsell-merchandise",
    name: "UpsellItems",
    component: () => import("@/views/settings/upsell-items/UpsellItemsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/upsell-merchandise/create",
    name: "CreateUpsellItem",
    component: () =>
      import("@/views/settings/upsell-items/CreateUpsellItemPage"),
    props: true,
    meta: {
      authRequired: true,
      permission: "upsellItems.create",
    },
  },
  {
    path: "/settings/upsell-merchandise/:id",
    name: "EditUpsellItem",
    component: () =>
      import("@/views/settings/upsell-items/_id/EditUpsellItemPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/permissions",
    name: "Permissions",
    component: () => import("@/views/settings/permissions/PermissionsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/superadmin",
    name: "Superadmin",
    component: () => import("@/views/settings/superadmin/SuperadminPage"),
    meta: {
      authRequired: true,
      isSuperadmin: true,
    },
  },
  {
    path: "/settings/superadmin/create",
    name: "SuperadminCreate",
    component: () => import("@/views/settings/superadmin/SuperadminCreatePage"),
    meta: {
      authRequired: true,
      isSuperadmin: true,
    },
  },
  {
    path: "/settings/integrations",
    name: "Integrations",
    component: () =>
      import("@/views/settings/integrations/IntegrationsListPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/integrations/create",
    name: "CreateIntegration",
    component: () =>
      import("@/views/settings/integrations/CreateIntegrationPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/settings/integrations/:id",
    name: "EditIntegration",
    component: () =>
      import("@/views/settings/integrations/_id/EditIntegrationPage"),
    meta: {
      authRequired: true,
    },
  },
  // Pages
  {
    path: "/products",
    name: "Products",
    component: () => import("@/views/products/ProductsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    component: () => import("@/views/products/CreateProductPage"),
    meta: {
      authRequired: true,
      permission: "products.create",
    },
  },
  {
    path: "/products/:id",
    name: "EditProduct",
    component: () => import("@/views/products/_id/EditProductPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: () => import("@/views/bookings/BookingsPage"),
    props: true,
    meta: {
      authRequired: true,
    },
  },

  {
    path: "/bookings/create",
    name: "BookingCreate",
    component: () => import("@/views/bookings/CreateBookingPage"),
    props: true,
    meta: {
      authRequired: true,
      permission: "bookings.create",
    },
  },
  // {
  //   path: "/bookings/:status",
  //   name: "BookingsByStatus",
  //   component: () => import("@/views/bookings/BookingsPage"),
  //   props: true,
  //   meta: {
  //     authRequired: true,
  //   },
  // },
  {
    path: "/bookings/:id/view",
    name: "BookingDetail",
    component: () => import("@/views/bookings/_id/DetailBookingPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/bookings/:id/edit",
    name: "BookingEdit",
    component: () => import("@/views/bookings/_id/EditBookingPage"),
    meta: {
      authRequired: true,
      permission: "bookings.edit",
    },
  },
  {
    path: "/bookings/:id/checkout",
    name: "BookingCheckout",
    component: () => import("@/views/bookings/_id/CheckoutBookingPage"),
    meta: {
      authRequired: true,
      permission: "bookings.collect",
    },
  },
  {
    path: "/bookings/:id/refund",
    name: "BookingRefund",
    component: () => import("@/views/bookings/_id/RefundBookingPage"),
    meta: {
      authRequired: true,
      permission: "bookings.refund",
    },
  },
  {
    path: "/bookings/:id/signatures",
    name: "BookingSignatures",
    component: () =>
      import("@/views/bookings/_id/signaturesBooking/BookingSignaturesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/bookings/:id/signatures/sign",
    name: "BookingSignaturesSign",
    component: () =>
      import(
        "@/views/bookings/_id/signaturesBooking/BookingSignaturesSignPage"
      ),
    meta: {
      authRequired: true,
      permission: "signatures.create",
      layout: "Auth",
      layoutProps: {
        isWide: true,
      },
    },
  },
  {
    path: "/bookings/:id/signatures/:signatureId",
    name: "BookingSignatureDetail",
    component: () =>
      import(
        "@/views/bookings/_id/signaturesBooking/_signatureId/BookingSignatureDetailsPage"
      ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/bookings/:id/personal-info",
    name: "BookingPersonalInfo",
    component: () => import("@/views/bookings/_id/PersonalInfoBookingPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/calendar/CalendarPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/waivers",
    name: "Waivers",
    component: () => import("@/views/waivers/WaiversPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/waivers/create",
    name: "CreateWaiver",
    component: () => import("@/views/waivers/CreateWaiverPage"),
    meta: {
      authRequired: true,
      permission: "waivers.create",
    },
  },
  {
    path: "/waivers/:id",
    name: "EditWaiver",
    component: () => import("@/views/waivers/_id/EditWaiverPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/signatures/:id",
    name: "SignatureDetails",
    component: () => import("@/views/signatures/_id/SignatureDetailsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/resources",
    name: "Resources",
    component: () => import("@/views/resources/ResourcesPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/users/UsersPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/users/:id",
    name: "EditUser",
    component: () => import("@/views/users/_id/EditUserPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/dashboard/DashboardPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard/stripe-balance",
    name: "StripeBalanceDetails",
    component: () => import("@/views/dashboard/StripeBalanceDetailsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard/:statKey",
    name: "DashboardStatisticsDetails",
    component: () => import("@/views/dashboard/DashboardStatisticsDetailsPage"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authMiddleware);
router.beforeEach(onboardMiddleware);
router.beforeEach(permissionsMiddleware);
router.beforeEach(superadminMiddleware);
// router.beforeEach(venueQueryMiddleware);

export default router;
