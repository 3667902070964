import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class StripeService extends ServiceBase {
  static getOnboardLink() {
    return this.callApi({
      method: "POST",
      baseURL,
      url: "/private/stripe/onboard-link",
      data: {
        returnUrl: window.location.origin,
      },
    });
  }

  static getBalance() {
    return this.callApi({
      method: "GET",
      baseURL,
      url: "/private/stripe/balance",
    });
  }

  static getPayouts(params) {
    return this.callApi({
      method: "GET",
      baseURL,
      url: "/private/stripe/payouts",
      params,
    });
  }

  static depositBalance(amount) {
    return this.callApi({
      method: "POST",
      baseURL,
      url: "/private/stripe/balance/deposit",
      data: {
        amount,
      },
    });
  }
}
