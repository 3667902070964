export default {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_DETAILED_LIST(state, detailedList) {
    state.detailedList = detailedList;
  },
  SET_DETAILED_LIST_LENGTH(state, detailedListLength) {
    state.detailedListLength = detailedListLength;
  },
  SET_LAST_DOC(state, lastDoc) {
    state.lastDoc = lastDoc;
  },
  SET_EDITED_ITEM(state, item) {
    state.editedItem = item;
  },
  SET_FREE_SLOTS(state, freeSlots) {
    state.freeSlots = freeSlots;
  },
  SET_ALL_SLOTS(state, allSlots) {
    state.allSlots = allSlots;
  },
  SET_SIGNATURES(state, signatures) {
    state.signatures = signatures;
  },
  SET_DETAILED_SIGNATURE(state, signature) {
    state.detailedSignature = signature;
  },
  SET_ACTIVE_FILTER(state, filter) {
    state.activeFilter = filter;
  },
  SET_DATE_RANGE_FILTER(state, dates) {
    state.dateRangeFilter = dates;
  },
  SET_PERIOD_FILTER(state, period) {
    state.periodFilter = period;
  },
  SET_DATES_AVAILABILITIES(state, datesAvailabilities) {
    state.datesAvailabilities = datesAvailabilities;
  },
  REMOVE_FROM_DATES_AVAILABILITIES(state) {
    state.datesAvailabilities = {};
  },
  SET_ACTIVE_PRODUCT_FILTER(state, value) {
    state.activeProductFilter = value;
  },
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },
  SET_SEARCH_FIELD(state, field) {
    state.searchField = field;
  },
  SET_EDITED_ITEM_PAYMENT_METHODS(state, methods) {
    state.editedItemPaymentMethods = methods;
  },
  SET_SELECTED_VIEW(state, view) {
    state.selectedView = view;
  },
  SET_ACTIVE_SORT_FIELD(state, field) {
    state.activeSortField = field;
  },
  SET_ACTIVE_SORT_DIRECTION(state, direction) {
    state.activeSortDirection = direction;
  },
};
