export default {
  checkPermission(state, _, rootState) {
    return (requiredPermission) => {
      const [entity, actions] = requiredPermission.split(".");
      const permissions = rootState.venues.selectedVenue?.permissions;
      if (state.isSuperadmin) {
        return true;
      }
      if (permissions) {
        const requiredRoles = permissions[entity][actions];
        return requiredRoles?.includes(state.venueUserJunction?.role);
      }
      return false;
    };
  },
};
