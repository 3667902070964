import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { where } from "firebase/firestore";

export default {
  async fetchMailingTemplates({ commit, rootState }, { event, isPaused }) {
    const params = {
      event: event ? where("event", "==", event) : undefined,
      isPaused:
        isPaused !== undefined ? where("isPaused", "==", isPaused) : undefined,
    };
    if (rootState.venues.selectedVenue?.id) {
      const mailingTemplates = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/mailingTemplates`,
        ...Object.values(params)
      );
      commit("SET_LIST", mailingTemplates);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchMailingTemplateById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const mailingTemplate = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/mailingTemplates`,
        id
      );
      commit("SET_EDITED_ITEM", mailingTemplate);
    }
  },
  async updateMailingTemplate({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/mailingTemplates`,
        id,
        data
      );
    }
  },
  async createMailingTemplate({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/mailingTemplates`,
        data
      );
    }
  },
  async deleteMailingTemplate({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/mailingTemplates`,
        id
      );
    }
  },
};
