import {
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { deleteDoc, doc, runTransaction, where } from "firebase/firestore";
import { auth, db } from "@/plugins/firebase";
import { RoleEnum } from "@/helpers/enums";

export default {
  async fetchVenues({ commit }) {
    if (auth.currentUser) {
      const junctions = await getCollection(
        "venueUserJunctions",
        where("userId", "==", auth.currentUser.uid)
      );
      const venues = (
        await Promise.all(
          junctions.map((junction) => {
            return getDocById("venues", junction.venueId);
          })
        )
      ).filter(Boolean);
      commit("SET_LIST", venues);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchVenue({ commit, state, rootState }) {
    try {
      let venue = null;
      if (rootState.users.isSuperadmin && state.queryVenue) {
        venue = await getDocById("venues", state.queryVenue);
      }
      if (!venue) {
        const junctions = await getCollection(
          "venueUserJunctions",
          where("userId", "==", auth.currentUser.uid)
        );
        const newSelectedVenueId =
          junctions.find(
            (junction) => junction.venueId === state.selectedVenue?.id
          )?.venueId || junctions[0]?.venueId;
        venue = newSelectedVenueId
          ? await getDocById("venues", newSelectedVenueId)
          : null;
      }
      commit("SET_SELECTED_VENUE", venue);
    } catch {
      commit("SET_SELECTED_VENUE", null);
    }
  },
  async createVenue(_, { id, data }) {
    const userId = auth.currentUser.uid;
    await runTransaction(db, async (transaction) => {
      let exists;
      try {
        const venueSnap = await transaction.get(doc(db, "venues", id));
        exists = venueSnap.exists();
      } catch (ex) {
        exists = false;
      }

      if (exists) {
        throw `Venue with ID '${id}' already exists`;
      }

      transaction.set(doc(db, "venues", id), data);
      transaction.set(doc(db, "venueUserJunctions", `${id}_${userId}`), {
        userId,
        venueId: id,
        role: RoleEnum.OWNER,
      });
    });
  },
  async updateVenue({ state }, { id, data }) {
    const ensuredId = id || state.selectedVenue.id;
    await updateDocument(`venues`, ensuredId, data);
  },
  async deleteUserFromVenue(_, { id }) {
    const userId = auth.currentUser.uid;
    await deleteDoc(doc(db, "venueUserJunctions", `${id}_${userId}`));
  },
  async addUserToVenue(_, { id }) {
    const userId = auth.currentUser.uid;

    await runTransaction(db, async (transaction) => {
      let exists;
      try {
        const venueSnap = await transaction.get(
          doc(db, "venueUserJunctions", `${id}_${userId}`)
        );
        exists = venueSnap.exists();
      } catch (ex) {
        exists = false;
      }

      if (!exists) {
        transaction.set(doc(db, "venueUserJunctions", `${id}_${userId}`), {
          userId,
          venueId: id,
          role: RoleEnum.OWNER,
        });
      }
    });
  },
};
