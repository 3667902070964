import { auth } from "@/plugins/firebase";

export const authMiddleware = (to, from, next) => {
  const user = auth.currentUser;
  const loggedIn = !!user && !user.disabled;
  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }
  if (to.path === "/login" && loggedIn) {
    return next("/");
  }
  return next();
};
