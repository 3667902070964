<template>
  <custom-modal
    :name="name"
    :classes="['password-dialog']"
    @before-close="passwordDialog.cancel()"
  >
    <div class="password-dialog__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(name)"
      />
    </div>
    <template v-slot:header>
      <p class="password-dialog__title">Confirm password</p>
    </template>
    <Form v-slot="{ invalid }">
      <FormItem rules="required" class="mb-48" v-slot="{ isError }">
        <Input v-model="password" placeholder="Password" :is-error="isError" />
      </FormItem>
      <Button
        is-block
        :is-disabled="invalid"
        @click="passwordDialog.ok(password)"
      >
        Confirm
      </Button>
    </Form>
  </custom-modal>
</template>

<script>
import Vue from "vue";

const state = Vue.observable({
  active: false,
  password: "",
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));

const reset = () => {
  state.active = false;
  state.password = "";
};

export const passwordDialog = {
  get state() {
    return state;
  },

  open() {
    state.active = true;
    return dialogPromise();
  },

  ok(password) {
    close(password);
    reset();
  },

  cancel() {
    close(false);
    reset();
  },
};

export default {
  name: "PasswordDialog",
  data() {
    return {
      password: "",
      passwordDialog,
      name: "password-dialog",
    };
  },
  computed: {
    isActive() {
      return this.passwordDialog.state.active;
    },
  },
  watch: {
    isActive(newValue) {
      const action = newValue ? this.$modal.show : this.$modal.hide;
      action(this.name);
    },
  },
};
</script>

<style lang="scss">
.password-dialog {
  padding: 40px 24px;
}
</style>

<style lang="scss" scoped>
.password-dialog {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 24px;
  }
}
</style>
