import UsersService from "@/api/services/UsersService";
import { getDocById, updateDocument } from "@/helpers/firebase-helpers";
import { auth } from "@/plugins/firebase";

export default {
  async fetchVenueUserJunction({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const junction = await getDocById(
        "venueUserJunctions",
        `${rootState.venues.selectedVenue.id}_${auth.currentUser.uid}`
      );
      commit("SET_VENUE_USER_JUNCTION", junction);
    }
  },
  async updateVenueUserJunction({ rootState, dispatch }, data) {
    await updateDocument(
      "venueUserJunctions",
      `${rootState.venues.selectedVenue.id}_${auth.currentUser.uid}`,
      data
    );
    await dispatch("fetchVenueUserJunction");
  },
  async fetchIsSuperadmin({ commit }) {
    const token = await auth.currentUser.getIdTokenResult();
    commit("SET_IS_SUPERADMIN", !!token.claims.isSuperadmin);
  },
  async fetchUsers({ commit, state }) {
    const params = {
      role: state.roleFilter,
      sort: state.sort,
    };
    const users = await UsersService.getUsers(params);
    commit("SET_LIST", users);
  },
  async fetchUserById({ commit }, id) {
    const user = await UsersService.getUserById(id);
    commit("SET_EDITED_ITEM", user);
  },
  async fetchOnboardingProgress({ commit }) {
    const progress = await getDocById(
      "onboardingProgress",
      auth.currentUser.uid
    );
    commit("SET_CURRENT_ONBOARDING_STEP", progress.currentStep);
    commit("SET_IS_ONBOARDING_FINISHED", progress.isFinished);
  },
  async updateOnboardingProgress({ commit }, data) {
    await updateDocument("onboardingProgress", auth.currentUser.uid, data);

    if (data.currentStep || data.currentStep === 0) {
      commit("SET_CURRENT_ONBOARDING_STEP", data.currentStep);
    }
    commit("SET_IS_ONBOARDING_FINISHED", !!data.isFinished);
  },
};
