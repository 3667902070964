export default {
  SET_SELECTED_VENUE(state, venue) {
    state.selectedVenue = venue;
  },
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_QUERY_VENUE(state, queryVenue) {
    state.queryVenue = queryVenue;
  },
};
