<template>
  <div class="sidebar-wrapper">
    <transition name="slide-right">
      <div
        class="sidebar"
        :class="{ 'm-collapsed': isCollapsed }"
        v-show="innerActive || !['xs', 'sm'].includes($mq)"
      >
        <div v-if="['xs', 'sm'].includes($mq)" class="sidebar-header">
          <Icon name="close" is-clickable @click="innerActive = false" />
          <router-link
            v-if="user"
            to="/settings/profile"
            class="user"
            @click.native="innerActive = false"
          >
            <Avatar :url="user.photoURL" :text="user.displayName" />
          </router-link>
        </div>
        <div class="menu">
          <div>
            <div v-for="(item, index) in routes" :key="index">
              <router-link
                class="menu-item"
                active-class="active"
                :class="{ disabled: item.isDisabled }"
                :to="item.route"
                :disabled="item.isDisabled"
                v-bind="item.attributes"
                v-tooltip.right="{
                  content: isCollapsed ? item.label : '',
                  ...tooltipOptions,
                }"
                @click.native="onMenuItemClick(item)"
              >
                <Icon :name="item.icon" />
                <span v-show="!isCollapsed" class="menu-item-label">
                  {{ item.label }}
                </span>
              </router-link>
            </div>
          </div>
          <div v-if="['xs', 'sm'].includes($mq)">
            <LogoutButton class="mb-24" />
          </div>
          <div
            v-else
            class="menu-item"
            v-tooltip.right="{
              content: isCollapsed ? 'Expand' : '',
              ...tooltipOptions,
            }"
            @click="isCollapsed = !isCollapsed"
          >
            <Icon :name="isCollapsed ? 'arrow-right' : 'arrow-left'" />
            <span v-show="!isCollapsed" class="menu-item-label">
              Collapse
            </span>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-show="innerActive && ['xs', 'sm'].includes($mq)"
        class="overlay"
        @click="innerActive = false"
      />
    </transition>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { logout } from "@/helpers/firebase-helpers";
import { SIDEBAR_MENU_ITEMS } from "@/helpers/mocks";
import LogoutButton from "@/components/auth/LogoutButton";

export default {
  name: "Sidebar",
  components: { LogoutButton },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      routes: SIDEBAR_MENU_ITEMS,
      currentUser: auth.currentUser,
      tooltipOptions: {
        boundariesElement: "body",
        offset: 4,
        classes: "sidebar__tooltip",
      },
    };
  },
  computed: {
    user() {
      return this.currentUser;
    },
    innerActive: {
      get() {
        return this.active;
      },
      set(newValue) {
        this.$emit("update:active", newValue);
      },
    },
    isCollapsed: {
      get() {
        return (
          this.$store.state.isSidebarCollapsed &&
          !["xs", "sm"].includes(this.$mq)
        );
      },
      set(newVal) {
        this.$store.commit("SET_IS_SIDEBAR_COLLAPSED", newVal);
      },
    },
  },
  methods: {
    logout,
    onMenuItemClick(item) {
      if (!item.isDisabled) {
        this.innerActive = false;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar__tooltip {
  z-index: 400;
}
</style>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: $modal-overlay;
  transition: all 0.3s ease;
  pointer-events: all;
}

.sidebar-wrapper {
  background-color: transparent;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  @media (min-width: $media-laptop) {
    position: sticky;
    top: 2px;
  }

  .sidebar {
    pointer-events: all;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 310px;
    padding: 16px 0 0;
    background-color: $white;
    box-shadow: $box-shadow-mini;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    z-index: 270;
    border-right: 1px solid $secondary-300;

    @media (min-width: $media-laptop) {
      position: static;
      width: 270px;
      height: 100%;
    }

    &.m-collapsed {
      width: auto;
    }

    .user {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;
    }

    .sidebar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 24px;
      margin-bottom: 72px;
      gap: 8px;
    }

    .menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      flex: 1;
      overflow: auto;

      .menu-item {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 16px 24px;
        color: $secondary-500;
        cursor: pointer;
        transition: 0.3s;
        text-decoration: none;

        svg {
          transition: 0.3s;
          fill: $secondary-500 !important;
        }

        &:hover {
          color: #778192;

          svg {
            fill: #778192 !important;
          }
        }

        &.active {
          color: $primary;

          svg {
            fill: $primary !important;
          }
        }

        &.disabled {
          color: $secondary-300;
          pointer-events: none;

          svg {
            fill: $secondary-300 !important;
          }
        }

        .menu-item-label {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
        }

        &.child {
          padding: 0 0 16px 68px;

          .menu-item-label {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
  }
}
</style>
