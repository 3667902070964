import ServiceBase from "@/api/core";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class BankAccountsService extends ServiceBase {
  static getBankAccounts() {
    const url = "/private/bank-accounts";

    return this.callApi({
      method: "GET",
      url,
      baseURL,
    });
  }

  static createBankAccount(data) {
    const url = "/private/bank-accounts";

    return this.callApi({
      method: "POST",
      url,
      baseURL,
      data,
    });
  }

  static makeBankAccountDefault(id, isDefaultForCurrency) {
    const url = `/private/bank-accounts/${id}`;

    return this.callApi({
      method: "PATCH",
      url,
      baseURL,
      data: {
        isDefaultForCurrency,
      },
    });
  }

  static deleteBankAccount(id) {
    const url = `/private/bank-accounts/${id}`;

    return this.callApi({
      method: "DELETE",
      url,
      baseURL,
    });
  }
}
