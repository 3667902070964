import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";

export default {
  async fetchUpsellItems({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const upsellItems = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/upsellItems`
      );
      commit("SET_LIST", upsellItems);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchUpsellItemById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const upsellItem = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/upsellItems`,
        id
      );
      commit("SET_EDITED_ITEM", upsellItem);
    }
  },
  async updateUpsellItem({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/upsellItems`,
        id,
        data
      );
    }
  },
  async createUpsellItem({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/upsellItems`,
        data
      );
    }
  },
  async deleteUpsellItem({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/upsellItems`,
        id
      );
    }
  },
};
