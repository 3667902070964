export default {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_EDITED_ITEM(state, item) {
    state.editedItem = item;
  },
  SET_BOOKINGS(state, bookings) {
    state.bookings = bookings;
  },
  SET_BOOKINGS_LAST_DOC(state, lastDoc) {
    state.lastBookingDoc = lastDoc;
  },
};
