import actions from "./actions";
import mutations from "./mutations";
import {
  BookingPageViewEnum,
  BookingSearchFieldEnum,
  BookingTableSortFieldEnum,
  SortDirectionEnum,
} from "@/helpers/enums";

const state = {
  list: [],
  detailedList: [],
  detailedListLength: 0,
  lastDoc: undefined,
  editedItem: null,
  freeSlots: [],
  allSlots: [],
  signatures: [],
  detailedSignature: null,
  activeFilter: null,
  activeProductFilter: null,
  dateRangeFilter: [null, null],
  periodFilter: "",
  datesAvailabilities: {},
  searchQuery: "",
  searchField: BookingSearchFieldEnum.REGISTRATION_CODE,
  editedItemPaymentMethods: [],
  selectedView: BookingPageViewEnum.DETAILED,
  activeSortField: BookingTableSortFieldEnum.CREATION_DATE,
  activeSortDirection: SortDirectionEnum.DESC,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
