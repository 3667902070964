import Vue from "vue";

const state = Vue.observable({
  type: "alert",
  active: false,
  message: "",
  title: "",
  okText: "",
  cancelText: "",
  isHtml: false,
});

let close;
const dialogPromise = () => new Promise((resolve) => (close = resolve));

const open = ({ title, message, okText, cancelText, isHtml }) => {
  state.message = message;
  state.title = title;
  state.okText = okText;
  state.cancelText = cancelText;
  state.isHtml = isHtml;
  state.active = true;
  return dialogPromise();
};

const reset = () => {
  state.active = false;
  state.message = "";
  state.title = "";
  state.okText = "";
  state.cancelText = "";
};

const dialog = {
  get state() {
    return state;
  },

  alert(options) {
    state.type = "alert";
    return open(options);
  },
  confirm(options) {
    state.type = "confirm";
    return open(options);
  },

  ok() {
    close(true);
    reset();
  },

  cancel() {
    close(false);
    reset();
  },
};

export default dialog;
