<template>
  <div class="header">
    <Logo v-if="!['xs', 'sm'].includes($mq)" />
    <Icon
      v-else
      name="burger"
      color="secondary-500"
      is-clickable
      @click="$emit('burger-click')"
    />
    <div class="header__toolbar">
      <div class="header__venue-time">Venue's date & time {{ venueTime }}</div>
      <Button
        v-if="
          !isOnboardingFinished &&
          isNotSettingsRoute &&
          currentOnboardingStep !== -1
        "
        class="header__button-resume"
        is-small
        is-raised
        @click="handleOpenOnboardingGuide"
      >
        Resume guide
      </Button>
      <Loader v-if="isLoading" color="primary" size="s" />
      <Dropdown
        v-else-if="venueOptions.length > 1"
        :value="selectedVenue.id"
        :options="venueOptions"
        @select="handleVenueSelect"
      />
      <div v-else-if="selectedVenue" class="header__toolbar__venue">
        {{ selectedVenue.name }}
      </div>
      <template v-if="!['xs', 'sm'].includes($mq)">
        <div class="header__toolbar__divider" />
        <router-link
          v-if="user"
          to="/settings/profile"
          class="header__toolbar__user"
        >
          <Avatar :url="user.photoURL" :text="user.displayName" />
          <span v-if="isSuperadmin">👑</span>
        </router-link>
        <div class="header__toolbar__divider" />
        <LogoutButton class="header__toolbar__logout" />
      </template>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/common/Logo";
import { mapActions, mapMutations, mapState } from "vuex";
import Dropdown from "@/components/common/Dropdown";
import { auth } from "@/plugins/firebase";
import { logout } from "@/helpers/firebase-helpers";
import LogoutButton from "@/components/auth/LogoutButton";
import { RangeButtonsEnum } from "@/helpers/enums";
import momentTZ from "moment-timezone";

export default {
  name: "Header",
  components: { LogoutButton, Dropdown, Logo },
  data() {
    return {
      isLoading: false,
      currentUser: auth.currentUser,
      venueTime: "",
      venueTimerId: null,
    };
  },
  computed: {
    ...mapState({
      selectedVenue: (state) => state.venues.selectedVenue,
      currentOnboardingStep: (state) => state.users.currentOnboardingStep,
      isSuperadmin: (state) => state.users.isSuperadmin,
      venues: (state) => state.venues.list,
      isOnboardingFinished: (state) => state.users.isOnboardingFinished,
    }),
    venueOptions() {
      const options = this.venues.map((venue) => ({
        value: venue.id,
        name: venue.name,
      }));
      if (!options.some((option) => option.value === this.selectedVenue.id)) {
        options.unshift({
          value: this.selectedVenue.id,
          name: this.selectedVenue.name,
        });
      }
      return options;
    },
    isNotSettingsRoute() {
      return this.$route.name?.toLowerCase() !== "settings";
    },
    onboardingText() {
      if (this.currentOnboardingStep === -1) {
        return this.isOnboardingFinished
          ? "Run onboarding guide again"
          : "Run onboarding guide";
      }
      return "Continue onboarding guide";
    },
    user() {
      return this.currentUser;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchVenues();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchVenues: "venues/fetchVenues",
      deleteUserFromVenue: "venues/deleteUserFromVenue",
    }),
    ...mapMutations({
      SET_SELECTED_VENUE: "venues/SET_SELECTED_VENUE",
      SET_DASHBOARD_LAST_FETCHED_AT: "dashboard/SET_LAST_FETCHED_AT",
    }),
    logout,
    async handleVenueSelect(option) {
      if (this.selectedVenue.id !== option.value) {
        const newSelectedVenue = this.venues.find(
          (venue) => venue.id === option.value
        );
        if (newSelectedVenue) {
          this.SET_DASHBOARD_LAST_FETCHED_AT({
            [RangeButtonsEnum.TODAY]: null,
            [RangeButtonsEnum.THIS_WEEK]: null,
            [RangeButtonsEnum.WEEK]: null,
            [RangeButtonsEnum.THIS_MONTH]: null,
            [RangeButtonsEnum.MONTH]: null,
            [RangeButtonsEnum.THIS_YEAR]: null,
            [RangeButtonsEnum.YEAR]: null,
          });

          const isVenueEdit = localStorage.getItem("venue");
          if (isVenueEdit) {
            await this.deleteUserFromVenue({ id: isVenueEdit });
            this.SET_SELECTED_VENUE(newSelectedVenue);
            await localStorage.removeItem("venue");

            const routeData = this.$router.resolve({
              name: "Superadmin",
            });

            window.location.href = routeData.href;
          } else {
            this.SET_SELECTED_VENUE(newSelectedVenue);
            window.location.reload();
          }
        }
      }
    },
    handleOpenOnboardingGuide() {
      const step =
        this.currentOnboardingStep === -1 ? 0 : this.currentOnboardingStep;
      this.$tours.onboardingGuide?.start(step);
    },
    convertLocalToTimezone(localDt, localDtFormat, timezone) {
      const is24hFormat = this.selectedVenue.timeFormat === 24;
      const dateFormat = this.selectedVenue.dateFormat || "MM/DD/YY";

      return momentTZ(localDt, localDtFormat)
        .tz(timezone)
        .format(`${dateFormat} ${is24hFormat ? "HH:mm" : "hh:mm A"}`);
    },
    setVenueTime() {
      this.venueTimerId = setInterval(() => {
        this.venueTime = this.convertLocalToTimezone(
          new Date().toUTCString(),
          null,
          this.selectedVenue.timezone
        );
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.venueTimerId);
  },
  mounted() {
    this.setVenueTime();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 5px;
  border-bottom: 1px solid $secondary-300;
  z-index: 7;
  background-color: $white;
  height: 88px;

  @media (min-width: $media-laptop) {
    padding: 12px 44px 12px 68px;
    box-shadow: $box-shadow-mini;
    border-bottom: 1px solid $secondary-400;
  }

  &__button-resume {
    padding: 5px 10px !important;
    min-width: auto;
  }

  &__venue-time {
    min-width: 150px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $primary;
  }

  &__toolbar {
    display: flex;
    align-items: center;
    gap: 10px;

    &__divider {
      height: 20px;
      width: 1px;
      background-color: $secondary-300;
    }

    &__venue {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;
    }

    &__user {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;
    }

    &__logout {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
