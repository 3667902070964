import Vue from "vue";

const state = Vue.observable({
  variant: "secondary",
  active: false,
  message: "",
  timeout: null,
});

const reset = () => {
  state.active = false;
  state.message = "";
  state.variant = "secondary";
  clearTimeout(state.timeout);
  state.timeout = null;
};

const alert = {
  get state() {
    return state;
  },

  open({ message, variant, delay = 5000 }) {
    state.message = message;
    state.variant = variant;
    state.active = true;
    clearTimeout(state.timeout);
    state.timeout = setTimeout(reset, delay);
  },
};

export default alert;
