export default {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_EDITED_ITEM(state, item) {
    state.editedItem = item;
  },
  SET_SELECTED_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
};
