import store from "@/store";

export const permissionsMiddleware = (to, from, next) => {
  const resolve = () => {
    if (
      to.meta.permission &&
      !store.getters["users/checkPermission"](to.meta.permission)
    ) {
      return from ? next(from) : next("/");
    }
    return next();
  };
  if (!store.state.users.venueUserJunction?.role) {
    store.watch((state) => state.users.venueUserJunction?.role, resolve);
    return next();
  } else {
    return resolve();
  }
};
