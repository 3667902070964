import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocById,
  updateDocument,
} from "@/helpers/firebase-helpers";
import { limit, where } from "firebase/firestore";

const resetDefaultWidgetTemplate = async (venueId) => {
  const [defaultWidgetTemplate] =
    (await getCollection(
      `/venues/${venueId}/widgetTemplates`,
      where("isDefault", "==", true),
      limit(1)
    )) || [];
  if (defaultWidgetTemplate?.id) {
    await updateDocument(
      `/venues/${venueId}/widgetTemplates`,
      defaultWidgetTemplate.id,
      { isDefault: false }
    );
  }
};

export default {
  async fetchWidgetTemplates({ commit, rootState }) {
    if (rootState.venues.selectedVenue?.id) {
      const widgetTemplates = await getCollection(
        `/venues/${rootState.venues.selectedVenue.id}/widgetTemplates`
      );
      commit("SET_LIST", widgetTemplates);
    } else {
      commit("SET_LIST", []);
    }
  },
  async fetchWidgetTemplateById({ commit, rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      const widgetTemplate = await getDocById(
        `/venues/${rootState.venues.selectedVenue.id}/widgetTemplates`,
        id
      );
      commit("SET_EDITED_ITEM", widgetTemplate);
    }
  },
  async updateWidgetTemplate({ rootState }, { id, data }) {
    if (rootState.venues.selectedVenue?.id) {
      if (data.isDefault) {
        await resetDefaultWidgetTemplate(rootState.venues.selectedVenue.id);
      }
      await updateDocument(
        `/venues/${rootState.venues.selectedVenue.id}/widgetTemplates`,
        id,
        data
      );
    }
  },
  async createWidgetTemplate({ rootState }, data) {
    if (rootState.venues.selectedVenue?.id) {
      if (data.isDefault) {
        await resetDefaultWidgetTemplate(rootState.venues.selectedVenue.id);
      }
      await createDocument(
        `/venues/${rootState.venues.selectedVenue.id}/widgetTemplates`,
        data
      );
    }
  },
  async deleteWidgetTemplate({ rootState }, id) {
    if (rootState.venues.selectedVenue?.id) {
      await deleteDocument(
        `/venues/${rootState.venues.selectedVenue.id}/widgetTemplates`,
        id
      );
    }
  },
};
