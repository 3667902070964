var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tour',{staticClass:"onboarding-guide",attrs:{"name":_vm.tourName,"steps":_vm.steps,"options":_vm.options,"callbacks":_vm.callbacks},scopedSlots:_vm._u([{key:"default",fn:function(tour){return [(
          tour.steps[tour.currentStep] && tour.steps[tour.currentStep].isFixed
        )?_c('div',{staticClass:"onboarding-guide__overlay"}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.isVisible && tour.steps[tour.currentStep])?_c('v-step',{key:tour.currentStep,staticClass:"onboarding-popup",class:{
            'onboarding-popup--fixed': tour.steps[tour.currentStep].isFixed,
            'onboarding-popup--no-arrow':
              tour.steps[tour.currentStep].isArrowDisabled,
          },style:({ minWidth: tour.steps[tour.currentStep].minWidth }),attrs:{"step":tour.steps[tour.currentStep],"previous-step":tour.previousStep,"next-step":tour.nextStep,"stop":tour.stop,"skip":tour.skip,"is-first":tour.isFirst,"is-last":tour.isLast,"labels":tour.labels,"highlight":tour.highlight}},[_c('div',{staticClass:"onboarding-popup__header",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(tour.steps[tour.currentStep].title)+" "),_c('Icon',{staticClass:"onboarding-popup__close",attrs:{"name":"close","color":"secondary-100","is-clickable":""},on:{"click":tour.stop}})],1),_c('div',{staticClass:"onboarding-popup__content",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(tour.steps[tour.currentStep].content)+" ")]),_c('div',{staticClass:"onboarding-popup__footer",attrs:{"slot":"actions"},slot:"actions"},[_c('span',{staticClass:"onboarding-popup__progress"},[_vm._v(" "+_vm._s(tour.currentStep + 1)+" of "+_vm._s(tour.steps.length)+" ")]),_c('div',{staticClass:"onboarding-popup__actions"},[(!tour.isFirst)?_c('button',{staticClass:"onboarding-popup__back",on:{"click":tour.previousStep}},[_vm._v(" Back ")]):_vm._e(),(!tour.steps[tour.currentStep].waitForAction)?[(!tour.isLast)?_c('button',{staticClass:"onboarding-popup__next",on:{"click":tour.nextStep}},[_vm._v(" Next step "),_c('Icon',{attrs:{"name":"arrow-forward","color":"secondary-500","size":14}})],1):_c('button',{staticClass:"onboarding-popup__next",on:{"click":tour.finish}},[_vm._v(" Finish ")])]:(tour.steps[tour.currentStep].isSkippable)?_c('button',{staticClass:"onboarding-popup__next",on:{"click":function($event){return _vm.handleSkipStep(tour.steps[tour.currentStep])}}},[_vm._v(" Skip step "),_c('Icon',{attrs:{"name":"arrow-forward","color":"secondary-500","size":20}})],1):_vm._e()],2)])]):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }