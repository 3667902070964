export default {
  SET_DATE(state, date) {
    state.date = date;
  },
  SET_VIEW(state, view) {
    state.view = view;
  },
  SET_MONTH(state, month) {
    state.month = month;
  },
  SET_CALENDAR(state, calendar) {
    state.calendar = calendar;
  },
  SET_EDITING_GROUP(state, group) {
    state.editingGroup = group;
    if (!group?.id) {
      state.highlightedGroupId = null;
    }
  },
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },
  SET_PRODUCT_IDS(state, ids) {
    state.productIds = ids;
  },
  SET_HIGHLIGHTED_GROUP_ID(state, id) {
    state.highlightedGroupId = id;
  },
  SET_IS_DRAWER_OPEN(state, isOpen) {
    state.isDrawerOpen = isOpen;
  },
};
