import axios from "axios";
import { auth } from "@/plugins/firebase";
import store from "@/store";
import alert from "@/plugins/alert";

export const VENUE_HEADER_NAME = "Yoreferee-Booking-API-VenueId";

export default class ServiceBase {
  static async callApi({
    method,
    url,
    baseURL,
    data,
    params,
    signal,
    isVenueScoped = true,
    venueId,
  }) {
    let headers = {};
    const token = await auth.currentUser.getIdToken();
    headers.authorization = `Bearer ${token}`;
    if (isVenueScoped && (venueId || store.state.venues.selectedVenue)) {
      headers[VENUE_HEADER_NAME] =
        venueId || store.state.venues.selectedVenue.id;
    }
    try {
      const response = await axios.request({
        method,
        url,
        baseURL,
        data,
        params,
        headers,
        signal,
      });
      return response.data;
    } catch (err) {
      let message = null;
      if (err.response) {
        const { data } = err.response;
        message =
          data.message ||
          data.raw?.message ||
          data.error?.message ||
          data.error?.raw?.message ||
          "Internal server error. Try again later";
      } else if (err.request) {
        message = "The server did not respond to the request. Try again later";
      }
      if (message) {
        alert.open({
          message,
          variant: "danger",
        });
      }
      throw err;
    }
  }
}
